import React from "react";
import {
  handleStyleClick,
  handleSearchClick,
  handleCityClick,
  handleVenueClick,
  handleDateClick,
  handlePolicyClick,
} from "../../functions/1_handle_clicks";
import { useNavigate, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="footerWrap">
      <div className="footerGrid">




        <div className="footerBlock">
          <div
            className="sideMenuElementBig_Foot"
            onClick={() => {
              handleSearchClick(navigate);
            }}
          >
            Buscador de conciertos
          </div>
          

        </div>

        <div className="footerBlock">
          <div className="sideMenuElementBig_Foot">Contacta con nosotros</div>

          <div className="sideMenuElementBig_Foot">Privacidad</div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handlePolicyClick();
            }}
          >
            Política de privacidad
          </div>
        </div>
        <div className="footerBlock">
          <div className="sideMenuElementBig_Foot">Conciertos por ciudad</div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Madrid" });
            }}
          >
            Conciertos en Madrid
          </div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Barcelona" });
            }}
          >
            Conciertos en Barcelona
          </div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Sevilla" });
            }}
          >
            Conciertos en Sevilla
          </div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Santander" });
            }}
          >
            Conciertos en Santander
          </div>


          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Oviedo" });
            }}
          >
            Conciertos en Oviedo
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Salamanca" });
            }}
          >
            Conciertos en Salamanca
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Valencia" });
            }}
          >
            Conciertos en Valencia
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "León" });
            }}
          >
            Conciertos en León
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Cáceres" });
            }}
          >
            Conciertos en Cáceres
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Granada" });
            }}
          >
            Conciertos en Granada
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleCityClick({ city: "Almería" });
            }}
          >
            Conciertos en Almería
          </div>






          
        </div>
        <div className="footerBlock">
          <div className="sideMenuElementBig_Foot">Conciertos por escenario</div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "WiZink Center" });
            }}
          >
            Conciertos en el WiZink Center
          </div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "Estadio Santiago Bernabéu" });
            }}
          >
            Conciertos en el Estadio Santiago Bernabéu
          </div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "Hï Ibiza" });
            }}
          >
            Conciertos en Hï Ibiza
          </div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "Auditorio Starlite" });
            }}
          >
            Conciertos en Starlite
          </div>
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "La Riviera" });
            }}
          >
            Conciertos en la Riviera
          </div>
          
          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "Palau Sant Jordi" });
            }}
          >
            Conciertos en el Palau Sant Jordi
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "Sala Apolo" });
            }}
          >
            Conciertos en la Sala Apolo
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "Sala But" });
            }}
          >
            Conciertos en la Sala But
          </div>

          <div
            className="sideMenuElement_Foot"
            onClick={() => {
              handleVenueClick({ concert_place: "Ushuaïa Ibiza" });
            }}
          >
            Conciertos en Ushuaïa Ibiza
          </div>
          






          
        </div>
      </div>
    </div>
  );
}

export default Footer;
