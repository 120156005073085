import './11_Sidemenu.css';
import React from "react";
import {
  handleSearchClick,
  handleCityClick,
  handleVenueClick,
  handleLoginPage,
  handleUserClick
} from "../../functions/1_handle_clicks";
import { useAuth } from "../../functions/2_auth_context";
function SideMenu({ isOpen, toggleMenu }) {
  const { userInfo, handleLogout } = useAuth();
  return (
    <div className={`side-menu ${isOpen ? "open" : ""}`}>
      <div className="sideMenuWrapper">
        <div className='side__closeWrap'>
          <div className='side__close' onClick={toggleMenu}></div>
        </div>
        <div className="sideMenuElementBig">Acerca de Eventhio</div>
        <div
          className="sideMenuElementBig"
          onClick={() => {
            handleSearchClick(); // Second action: Navigate
          }}
        >
          Buscador de conciertos
        </div>


        <div className="sideMenuElementBig">Prueba también</div>
        <div
          className="sideMenuElement"
          onClick={() => {
            handleCityClick({ city: "Madrid" }); // Second action: Navigate
          }}
        >
          Conciertos en Madrid
        </div>
        <div
          className="sideMenuElement"
          onClick={() => {
            handleCityClick({ city: "Barcelona" }); // Second action: Navigate
          }}
        >
          Conciertos en Barcelona
        </div>
        <div
          className="sideMenuElement"
          onClick={() => {
            handleVenueClick({
              concert_place: "Estadio Santiago Bernabéu",
            }); // Second action: Navigate
          }}
        >
          Santiago Bernabéu
        </div>
        <div
          className="sideMenuElement"
          onClick={() => {
            handleVenueClick({ concert_place: "WiZink Center" }); // Second action: Navigate
          }}
        >
          Wizink Center
        </div>
        <div className="sideMenuElementBig">Contacta con nosotros</div>
        {userInfo ? (
          <>
            <div className="sideMenuElementBig" onClick={handleUserClick}>Ver mi usuario</div>
            <div
              className="sideMenuElement"
              onClick={() => {
                handleLogout();
              }}
            >
              Cerrar sesión
            </div>
          </>
        ) : (
          <div className="sideMenuElementBig" onClick={handleLoginPage}>Inicia sesión</div>
        )}

      </div>
    </div>
  );
}

export default SideMenu;
