import React, {useEffect} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import "./App.css";
import NavBar from "./components/10_Navbar/10_Navbar";
import HomePage from "./components/0_Homepage/0_Homepage";
import ConcertDetails from "./components/1_Concertpage/1_Concertpage";
import ArtistDetails from "./components/2_Artistpage/2_Artistpage";
import VenueDetais from "./components/3_Venuepage/3_Venuepage";
import CityDetails from "./components/4_Citypage/4_Citypage";
import { AuthProvider } from "./functions/2_auth_context";
import SearchPage from "./components/6_Searchpage/6_Searchpage";
import Footer from "./components/7_Footer/7_Footer";
import UserDetails from "./components/14_Userinfo/14_Userinfo";
import Privacy from "./components/regulatory/0_politica";
import { UserDetailsNew } from "./components/13_Newuser/13_Newuser";
import LoginPage from "./components/12_Login/12_Login";
import BlogDetails from "./components/15_Blog/15_Blog";
import {AllFestivals} from "./components/16_Festivals/16_Festivals";
import {SpecificFestivals} from "./components/16_Festivals/16_Festivals";
import { v4 as uuidv4 } from 'uuid';

function App() {
  useEffect(() => {
    let userId = localStorage.getItem('cookieId');
    if (!userId) {
      userId = uuidv4();
      localStorage.setItem('cookieId', userId);
    }
  }, []);
  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <NavBar />
          <Routes>
            <Route path="/inicio" element={<Navigate replace to="/" />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/conciertos/:artist_name/:venue/:spotify_artist_id/:concert_id" element={<ConcertDetails />} />
            <Route path="/artistas/:artist_name/:spotify_artist_id" element={<ArtistDetails />} />
            <Route path="/escenarios/:venue" element={<VenueDetais />} />
            <Route path="/ciudades/:city" element={<CityDetails />} />
            <Route path="/busqueda" element={<SearchPage />} />
            <Route path="/usuario" element={<UserDetails />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/nuevo" element={<UserDetailsNew />} />
            <Route path="/usuarionuevo" element={<UserDetailsNew />} />
            <Route path="/privacidad" element={<Privacy />} />
            <Route path="/blog" element={<BlogDetails />}/>
            <Route path="/festivales" element={<AllFestivals />}/>
            <Route path="/festivales/:festival_name" element={<SpecificFestivals />}/>
          </Routes>
          <Footer />
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
