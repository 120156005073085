import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  formatDate,
  convertTimestampToDateStr,
} from "../../functions/0_side_functions.js";
import {
  handleConcertClick,
  handleArtistClick,
  handleVenueClick,
  handleCityClick,
} from "../../functions/1_handle_clicks.js";
import SEO from "../../functions/3_SEO.js";

function SearchPage() {
  const [artistData, setArtistData] = useState([]);
  const [concertData, setConcertData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [locData, setLocData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const fetchAbortController = useRef(null);
  const [searchInitiated, setSearchInitiated] = useState(false);

  const getSearchQuery = useCallback(() => {
    return new URLSearchParams(location.search).get("b") || "";
  }, [location.search]);

  useEffect(() => {
    setSearchInput(getSearchQuery());
  }, [location.search, getSearchQuery]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchInput.trim()) {
        setSearchInitiated(true); // Set to true as soon as search starts
        navigate(`/busqueda?b=${encodeURIComponent(searchInput)}`, {
          replace: true,
        });
      } else {
        navigate(`/busqueda`, { replace: true });
        setSearchInitiated(false); // Reset when input is cleared
      }
    }, 250);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchInput, navigate]);

  useEffect(() => {
    const searchQuery = getSearchQuery();
    if (!searchQuery) {
      setLoading(false);
      setArtistData([]);
      setConcertData([]);
      setCityData([]);
      setLocData([]);
      return;
    }

    const fetchEventData = async () => {
      if (fetchAbortController.current) {
        fetchAbortController.current.abort();
      }
      fetchAbortController.current = new AbortController();
      const signal = fetchAbortController.current.signal;

      const queryString = new URLSearchParams({
        busqueda: searchQuery,
      }).toString();
      const url = `https://ftgofl0xr9.execute-api.eu-north-1.amazonaws.com/default/6_Search_bar_EU_NORTH_1?${queryString}`;
      try {
        setLoading(true); // Start loading
        const response = await fetch(url, { signal });
        const data = await response.json();
        setArtistData(JSON.parse(data.artists));
        setConcertData(JSON.parse(data.concerts));
        setCityData(JSON.parse(data.cities));
        setLocData(JSON.parse(data.concert_places));
      } catch (error) {
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchEventData();

    return () => {
      if (fetchAbortController.current) {
        fetchAbortController.current.abort();
      }
    };
  }, [location.search, getSearchQuery]);

  const clearSearchInput = () => {
    setSearchInput("");
    setArtistData([]);
    setConcertData([]);
    setCityData([]);
    setLocData([]);
  };

  const handleRecommendationClick = (recommendation) => {
    setSearchInput(recommendation);
  };

  const renderData = () => {
    if (!searchInitiated) {
      return (
        <></>
      );
    } else {
      if (searchInput === "") {
        return (
          <></>
        );
      }
      if (
        !loading && // Ensure this is not shown while loading
        artistData.length === 0 &&
        concertData.length === 0 &&
        cityData.length === 0 &&
        locData.length === 0
      ) {
        return (
          <div className="search__Nothing">
            <div className="search__NothingTexto">
              No se han encontrado resultados con esos criterios de búsqueda
            </div>
          </div>
        );
      }
      return (
        <>
          {artistData.length > 0 && (
            <>
              <div className="search__TitleArtist">Artistas</div>
              <div className="search__artistContainer">
                {artistData.map((artist) => (
                  <div
                    key={'artist' + artist.spotify_artist_id}
                    onClick={() => handleArtistClick(artist)}
                    className="search__ArtistSearch"
                  >
                    <div
                      className="search__PhotoArtist"
                      style={{
                        backgroundImage: `url(${artist.spotify_artist_photo})`,
                      }}
                    ></div>
                    <div className="artistSearchWrap">
                      <div className="search__artistT">Artista</div>
                      <div className="search__artistName">{artist.spotify_artist_name}</div>
                      {artist.count > 0 && (
                        <div className="search__numberConcerts">
                          {artist.count} conciertos
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {concertData.length > 0 && (
            <>
              <div className="search__TitleArtist">Conciertos</div>
              <div className="search__concertContainer">
                {concertData.map((concert) => (
                  <div
                    key={'concert' + concert.concert_id}
                    onClick={() => handleConcertClick(concert)}
                    className="search__ArtistSearch"
                  >
                    <div style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(${concert.concert_image})`,
                      backgroundSize: "cover",
                      borderRadius: "4px",
                    }}>
                      <div
                        className="search__PhotoConcert"
                        style={{
                          backgroundImage: `url(${concert.concert_image})`,
                        }}
                      ></div>
                    </div>
                    <div className="search__concertInfoWrap">
                      <div className="search__concertName">{concert.spotify_artist_name}</div>
                      <div className="search__concertPlace">{concert.city} - {concert.concert_place}</div>
                      <div className="search__concertDate">
                        {formatDate(convertTimestampToDateStr(concert.local_date_start))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {cityData.length > 0 && (
            <>
              <div className="search__TitleArtist">Ciudades</div>
              <div className="search__artistContainer">
                {cityData.map((city) => (
                  <div
                    key={'city' + city.city}
                    onClick={() => handleCityClick({ city: city.city })}
                    className="search__ArtistSearch"
                  >
                    <div className="artistSearchWrap">
                      <div className="search__City"></div>
                      <div className="search__artistT">Ciudad</div>
                      <div className="search__artistName">{city.city}</div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}



          {locData.length > 0 && (
            <>
              <div className="search__TitleArtist">Escenarios</div>
              <div className="search__artistContainer">
                {locData.map((venue) => (
                  <div
                    key={'venue' + venue.concert_place}
                    onClick={() => handleVenueClick({ concert_place: venue.concert_place })}
                    className="search__ArtistSearch"
                  >
                    <div className="artistSearchWrap">
                      <div className="search__Venue"></div>
                      <div className="search__artistT">Escenario</div>
                      <div className="search__artistName">{venue.concert_place}</div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      );
    }
  };

  const seoTitle = `Eventhio | Encuentra los mejores conciertos, artistas, ciudades y escenarios`;
  const seoDescription = `Encuentra los mejores conciertos, artistas, ciudades y escenarios. ¡Compra ya tus entradas!`;


  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
      />

      <div className="pageWrapper">
        <div className="concert__TopSpace"></div>

        <div className="pageWrapper_SecondLevel">
          <div className="search__FullWrap">
            <div className="seatch__TopSpace"></div>
            <div className="search__Title">Busca conciertos, artistas, escenarios, ciudades...</div>
            <div className="search__BarWrap">
              <div className="search__Glass"></div>
              <input
                type="text"
                className="search__Input"
                placeholder="Encuentra lo que quieras..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              ></input>
              <div className="search__clearInput" onClick={clearSearchInput}></div>
            </div>
            <div className="search__Recom_Wrap">
              <div className="search__Recom_Title">Recomendaciones:</div>
              {["Taburete", "Estadio Santiago Bernabéu", "Madrid", "Metallica"].map((rec, index) => (
                <div
                  key={index}
                  className="search__Recom_Rec"
                  onClick={() => handleRecommendationClick(rec)}
                >
                  {rec}
                </div>
              ))}
            </div>
            <div className="search__Results">
              {renderData()}
              <div style={{ height: "20px" }}></div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default SearchPage;
