import shareContent from "../../functions/4_Share_content.js";
import React, { useState, useEffect, useRef } from "react";
import SEO from "../../functions/3_SEO.js";
import {
  formatDate,
  formatDateformTimestamp,
  convertTimestampToDateStr,
  formatDateformTimestamp_full,
  formatDate_2
} from "../../functions/0_side_functions.js";
import { useParams } from "react-router-dom";
import {
  handleConcertClick,
  handleArtistClick,
  handleVenueClick,
  handleCityClick,
  handleCloseButton,
  handleLoginPage,
  openInNewTab
} from "../../functions/1_handle_clicks.js";
import GoogleMapComponent from "../../functions/5_GoogleMaps.js";
import "@googlemaps/extended-component-library/place_overview.js";
import { useAuth } from "../../functions/2_auth_context.js";
import { v4 as uuidv4 } from 'uuid';

function ConcertDetails() {
  const [concertSimilar, setConcertSimilar] = useState({});
  const [concertAll, setConcertAll] = useState({});
  const [concerSimilarArtist, setConcertSimilarArtist] = useState({});
  const [userLikedConcerts, setUserLikedConcerts] = useState({});
  const [artistAllConcerts, setArtistsAllConcerts] = useState({});
  const [restaurantAll, setRestaurantAll] = useState({});
  const [festivalData, setFestivalData] = useState({});
  const [festivalArtists, setFestivalArtists] = useState({});
  const [loading, setLoading] = useState(true);
  const { spotify_artist_id } = useParams();
  const { userInfo } = useAuth();
  const { concert_id } = useParams();
  const { venue } = useParams();
  const type_of_query = "Concerts";
  const locationCity = JSON.parse(localStorage.getItem("location")).city;

  console.log(locationCity)
  const queryString = new URLSearchParams({
    spotify_artist_id,
    concert_id,
    type_of_query,
    user_id: userInfo ? userInfo.eventhio_id : null,
    venue: venue,
    city_url: locationCity
  }).toString();


  const isConcertLiked = (concertIds) => {
    return concertIds.some((concertId) =>
      userLikedConcerts.some((concert) => concert.concert_id === concertId)
    );
  };

  const toggleLikedConcert = async (concertIds) => {
    if (!userInfo) {
      handleLoginPage();
      return;
    }

    const typeOfQuery = isConcertLiked(concertIds) ? "RemoveConcert" : "PostConcert";
    const payload = {
      type_of_query: typeOfQuery,
      user_id: userInfo.eventhio_id,
      concert_id: concertIds,
    };

    try {
      const queryStringData = new URLSearchParams(payload).toString();
      const url = `https://6l04gpt4e4.execute-api.eu-north-1.amazonaws.com/default/8_Database_post_delete_EU_NORTH_1?${queryStringData}`;
      const response = await fetch(url);

      if (response.ok) {
        setUserLikedConcerts((prevUserLikedConcerts) => {
          if (isConcertLiked(concertIds)) {
            return prevUserLikedConcerts.filter(
              (concert) => !concertIds.includes(concert.concert_id)
            );
          } else {
            return [
              ...prevUserLikedConcerts,
              ...concertIds.map((id) => ({
                eventhio_user_id: userInfo.eventhio_id,
                concert_id: id,
              })),
            ];
          }
        });
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    const saveInfo = async () => {
      let cookieId = localStorage.getItem('cookieId');
      if (!cookieId) {
        cookieId = uuidv4();
        localStorage.setItem('cookieId', cookieId);
      }

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let userId = userInfo && userInfo.eventhio_id ? userInfo.eventhio_id : null;

      const payload = {
        type_of_query: "PostInfo",
        url: window.location.href,
        user_id: userId,
        ev_cookie_id: cookieId,
      };
      try {
        const queryStringData = new URLSearchParams(payload).toString();
        const url = `https://6l04gpt4e4.execute-api.eu-north-1.amazonaws.com/default/8_Database_post_delete_EU_NORTH_1?${queryStringData}`;
        await fetch(url);
      } catch (error) {
      }
    };

    saveInfo();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const queryStringData = new URLSearchParams(queryString).toString();
        const url = `https://68gnhmjcw2.execute-api.eu-north-1.amazonaws.com/default/1_Complete_modal_EU_NORTH_1?${queryStringData}`;
        console.log(url)
        const response = await fetch(url, { signal });
        const data = await response.json();

        setConcertSimilar(JSON.parse(data.concert_similar_concerts));
        setConcertAll(JSON.parse(data.concert_all_concerts));
        setConcertSimilarArtist(JSON.parse(data.concert_similar_artist));
        setUserLikedConcerts(JSON.parse(data.user_liked_concerts));
        setArtistsAllConcerts(JSON.parse(data.artist_all_concerts));
        setRestaurantAll(JSON.parse(data.res_final));
        setFestivalData(JSON.parse(data.festival));
        setFestivalArtists(JSON.parse(data.festival_artists));

      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchEventData();
    return () => {
      abortController.abort();
    };
  }, [queryString]);

  const scrollRefs = useRef([]);

  if (!scrollRefs.current.length) {
    scrollRefs.current = Array(4)
      .fill()
      .map(() => React.createRef());
  }

  const scrollLeft = (index) => {
    const element = scrollRefs.current[index].current;
    if (element) {
      const scrollAmount = element.offsetWidth * 1;
      element.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const scrollRight = (index) => {
    const element = scrollRefs.current[index].current;
    if (element) {
      const scrollAmount = element.offsetWidth * 1;
      element.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };





  if (loading) {
    return <div className="loadingScreen"></div>;
  } else {
    console.log(concertAll)
    const divStyle = {
      backgroundImage: `url(${concertAll[0].concert_image})`,
      aspectRatio: "16 / 9",
    };

    /* SEO ELEMENTS */
    const seoTitle = `${concertAll[0].spotify_artist_name} - ${concertAll[0].concert_place}, ${concertAll[0].city} | Eventhio`;
    const seoDescription = `No te pierdas el concierto de ${concertAll[0].spotify_artist_name} en ${concertAll[0].concert_place} el próximo ${formatDateformTimestamp_full(concertAll[0].local_date_start)}. ¡Compra ya tus entradas!`;
    let seoUrlPrev;
    if (concertAll[0].concert_id.length === 1) {
      seoUrlPrev = concertAll[0].concert_id[0];
    } else {
      seoUrlPrev = concertAll[0].concert_id.sort()[0];
    }
    const seoUrl = `https://www.eventhio.com/conciertos/${concertAll[0].concert_place.replace(
      / /g,
      "-"
    )}/${concertAll[0].spotify_artist_name.replace(/ /g, "-")}/${concertAll[0].spotify_artist_id}/${seoUrlPrev}`;
    const seoPhoto = `${concertAll[0].concert_image}`;
    const combinedDataAndTitles = [
      { title: "Conciertos similares", data: concertSimilar.slice(0, 10) },
      {
        title: "Tambien te pueden interesar",
        data: concertSimilar.slice(10, 20),
      },
      { title: "No te lo puedes perder", data: concertSimilar.slice(20, 30) },
    ];

    const recommendedRestaurantFirst = restaurantAll.find(restaurant => restaurant.restaurant_position === 1);
    const recommendedRestaurantOther = restaurantAll.filter(restaurant => restaurant.restaurant_position !== 1);

    const festi_image_link = festivalData[0] && festivalData[0].festi_image !== '' ? festivalData[0].festi_image : false;
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoDescription}
          url={seoUrl}
          image={seoPhoto}
        />

        <div className="pageWrapper">
          <div className="concert__TopSpace"></div>
          <div className="pageWrapper_SecondLevel">
            <div className="concert_BackBlack" style={divStyle}></div>

            <div className="concert__artistPhoto" style={divStyle}>
              <div className="closeButtonWrap" onClick={() => handleCloseButton()}>
                <div className="closeButton"></div>
              </div>
            </div>
            <div className="concertInfo__Wrapper">
              <h1 className="artist_Name" onClick={() => handleArtistClick(concertAll[0])}>
                {concertAll[0].spotify_artist_name}
              </h1>
              <div className="concertInfo__Wrapper_Like">
                <div className="concertInfo__Wrapper_Like_Wrap">
                <div className="city_Name" onClick={() => handleCityClick(concertAll[0])}>
                      {concertAll[0].city}
                    </div>
                  <div className="venue_Name" onClick={() => handleVenueClick(concertAll[0])}>
                    {concertAll[0].concert_place}
                  </div>
                  <div className="date_Name">
                    {formatDate(convertTimestampToDateStr(concertAll[0].local_date_start))}
                  </div>
                  
                </div>
                <div onClick={() => toggleLikedConcert(concertAll[0].concert_id)}>
                  {isConcertLiked(concertAll[0].concert_id) ? (
                    <div className="concert__InterestedBox_I">
                      <div className="concert__InterestedBoxText_I">INTERESADO</div>
                    </div>
                  ) : (
                    <div className="concert__InterestedBox">
                      <div className="concert__InterestedBoxText">ME INTERESA</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="concert__Wrap">
              {concertAll.map((concert, index) => {
                return concert.concert_name.map((name, nameIndex) => {
                  return (
                    <div
                      className="concert__"
                      key={nameIndex + "_concert_similar_wrap"}
                    >
                      <div className="concert__Photo" style={divStyle}>
                      </div>
                      <div className="concert__InfoButtonWrap">
                        <div className="concert__InfoWrap">
                          <div className="concert__InfoName">{name}</div>
                          <div className="concert__InfoMoney">
                            {concert.min_price[nameIndex] === 0 && concert.max_price[nameIndex] === 0
                              ? "No hay precio disponible"
                              : concert.min_price[nameIndex] === concert.max_price[nameIndex]
                                ? `${concert.min_price[nameIndex]}€`
                                : `${concert.min_price[nameIndex]}€ - ${concert.max_price[nameIndex]}€`}
                          </div>


                        </div>
                        <div className="concert__Buy" onClick={() =>
                          openInNewTab(concert.concert_url[nameIndex])
                        }>COMPRAR</div>
                      </div>

                    </div>
                  );
                });
              })}
            </div>

            {concertAll[0].is_festival === 1 ? (
              <div className="concert__FestivalWrap">
                <h1 className="concert__Info__ArtistTitle">
                  {concertAll[0].concert_name}
                </h1>
                <div className="festi__Wrap">
                  {festivalData[0].festi_image && festivalData[0].festi_image !== '' ? (
                    <>
                      <div className="fest__Cartel_Back" style={{ backgroundImage: `linear-gradient(90deg, rgba(229, 253, 251,0.9) 0%, rgba(212, 238, 245,0.9) 50%, rgba(255, 233, 255,0.9) 100%), url(${festivalData[0].festi_image})` }}></div>
                      <div className="festi__Cartel" style={{ backgroundImage: `url(${festivalData[0].festi_image})` }}>
                      </div>
                    </>
                  ) : null}
                  {festivalData[0].festi_description && festivalData[0].festi_description !== '' ? (
                    <div>
                      {festivalData[0].festi_description.split('\\n\\n').map((paragraph, index) => (
                        <div key={index} className="festi__Para">{paragraph}</div>
                      ))}
                    </div>
                  ) : null}
                  <div>
                    {festivalArtists.length > 0 && (
                      <>
                        <h2 className="festi__Info__ArtistTitle">Otros artistas en {concertAll[0].concert_name}</h2>
                        <div className="concert__rowWrapFesti">

                          <div
                            className="concert__SimilarRowArtist"
                            key="event_artists"
                            ref={scrollRefs.current[3]}
                          >
                            {festivalArtists.map((artist, index) => {
                              const divStyle = {
                                backgroundImage: `url(${artist.spotify_artist_photo})`,
                                backgroundSize: "cover",
                              };
                              return (
                                <div key={artist.spotify_artist_id + index}>
                                  <div
                                    className="concert__artistSimilarConcert"
                                    style={divStyle}
                                    onClick={() => handleArtistClick(artist)}
                                  ></div>
                                  <div className="concert__Subbox">
                                    <div className="festi__SimilarArtistName">
                                      {artist.spotify_artist_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}





                  </div>

                  <div className="festi__Boton_Wrap">
                    <div className="festi__Boton">

                      <div>IR AL FESTIVAL</div>
                      <div className="festi__Boton_Arrow"></div>
                    </div>
                  </div>
                  <div style={{ height: "20px" }}></div>
                </div>
              </div>
            ) : null}



            <div className="concert__WhiteWrap">
              {artistAllConcerts.length > 0 && (
                <div className="concert__OtherConcertsWrap">
                  <div className="general__TopSpace"></div>
                  <h1 className="concert__Info__ArtistTitle">
                    Otros conciertos de {concertAll[0].spotify_artist_name}
                  </h1>
                  <div className="concert__Other_Wrap">
                    {artistAllConcerts.map((artist, artistIndex) => (
                      <div className="concert__Other" key={artistIndex}>
                        <div className="concert__dateInfoWrap">
                          <div className="date__Wrap">
                            <div className="date__">
                              {formatDate_2(
                                convertTimestampToDateStr(artist.local_date_start)
                              ).monthAbbreviation}
                            </div>
                            <div className="date_Number">
                              {formatDate_2(
                                convertTimestampToDateStr(artist.local_date_start)
                              ).day}
                            </div>
                            <div className="date__">
                              {formatDate_2(
                                convertTimestampToDateStr(artist.local_date_start)
                              ).year}
                            </div>
                          </div>

                          <div className="concert__InfoNameLocWrap">
                            <div className="concert__InfoName">
                              {artist.concert_name}
                            </div>
                            <div>
                              {artist.city} - {artist.concert_place}
                            </div>

                          </div>
                        </div>
                        <div className="concert__Ver" onClick={() => handleConcertClick(artist)}>VER</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="concert__WhiteWrap">

              {concertAll[0].spotify_artist_description &&
                concertAll[0].spotify_artist_description !== "" ? (
                <div className="concert__Info">
                  <h1 className="concert__Info__ArtistTitle">
                    Más acerca de{" "}
                    {concertAll[0].spotify_artist_name.replace("-", " ")}
                  </h1>
                  <div className="concert__Info_Text">
                    {concertAll[0].spotify_artist_description}
                  </div>
                </div>
              ) : (
                <div style={{ height: "0px" }}></div>
              )}

              <div className="concert__Info">
                <h1 className="concert__Info__ArtistTitle">
                  El escenario: {concertAll[0].concert_place}
                </h1>
                {concertAll[0].venue_description &&
                  concertAll[0].venue_description !== "" ? (
                  <div className="concert__Info_Text">
                    {concertAll[0].venue_description}
                  </div>
                ) : (
                  <div style={{ height: "0px" }}></div>
                )}
              </div>
              <div className="concert__Info_2">
                <GoogleMapComponent location={`${concertAll[0].concert_place}, ${concertAll[0].city}`} />
              </div>
            </div>
            <div className="concert__SimilarGap"></div>
            <div className="concert__Similar_Wrap">
              {concertSimilar.length > 0 && (
                <>
                  {combinedDataAndTitles.map((section, index) => (
                    <div key={"event_" + index}>
                      <h2 className="concert__SimilarTitle">{section.title}</h2>
                      <div className="concert__rowWrap">


                        <div
                          className="concert__SimilarRow"
                          ref={scrollRefs.current[index]}
                        >
                          {section.data.map((concert, index) => {
                            const divStyle = {
                              backgroundImage: `url(${concert.concert_image})`,
                            };
                            const divStyle2 = {
                              backgroundImage: `linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(${concert.concert_image})`,
                              backgroundSize: "cover",
                              borderRadius: "4px",
                            };
                            return (
                              <div key={concert.concert_id + index}>
                                <div>
                                  <div style={divStyle2}>
                                    <div
                                      className="concert__SimilarEventConcert"
                                      style={divStyle}
                                      onClick={() => handleConcertClick(concert)}
                                    ></div>
                                  </div>
                                  <div className="concert__Subbox">
                                    <div className="concert__SimilarEventName">
                                      {concert.spotify_artist_name}
                                    </div>
                                    <div className="concert__SimilarEventLocDate">
                                      {concert.city}
                                    </div>
                                    <div className="concert__SimilarEventDate">
                                      {formatDate(
                                        formatDateformTimestamp(
                                          concert.local_date_start
                                        )
                                      )}
                                    </div>
                                  </div>

                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {concerSimilarArtist.length > 0 && (
                <>
                  <h2 className="concert__SimilarTitle">Artistas similares</h2>
                  <div className="concert__rowWrap">
                    <div
                      className="scrollButton left"
                      onClick={() => scrollLeft(3)}
                    ></div>
                    <div
                      className="scrollButton right"
                      onClick={() => scrollRight(3)}
                    ></div>
                    <div
                      className="concert__SimilarRowArtist"
                      key="event_artists"
                      ref={scrollRefs.current[3]}
                    >
                      {concerSimilarArtist.map((artist, index) => {
                        const divStyle = {
                          backgroundImage: `url(${artist.spotify_artist_photo})`,
                          backgroundSize: "cover",
                        };
                        return (
                          <div key={artist.spotify_artist_id + index}>
                            <div
                              className="concert__artistSimilarConcert"
                              style={divStyle}
                              onClick={() => handleArtistClick(artist)}
                            ></div>
                            <div className="concert__Subbox">
                              <div className="concert__SimilarArtistName">
                                {artist.spotify_artist_name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

      </>
    );


  }
}

export default ConcertDetails;
