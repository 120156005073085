export const handleConcertClick = (info) => {
  const eventhioConcertId =
    Array.isArray(info.concert_id) &&
    info.concert_id.length > 1
      ? info.concert_id[0]
      : info.concert_id;
  
  const artistNameEncoded = encodeURIComponent(info.spotify_artist_name.replace(/ /g, '-'));
  const venueEncoded = encodeURIComponent(info.concert_place.replace(/ /g, '-'));
  const eventhioArtistIdEncoded = encodeURIComponent(info.spotify_artist_id);
  const eventhioConcertIdEncoded = encodeURIComponent(eventhioConcertId);
  const urlPath = `/conciertos/${artistNameEncoded}/${venueEncoded}/${eventhioArtistIdEncoded}/${eventhioConcertIdEncoded}`;
  window.location.href = `${window.location.origin}${urlPath}`;
};

export const handleArtistClick = (info) => {
  const artistNameEncoded = encodeURIComponent(info.spotify_artist_name.replace(/ /g, '-'));
  const spotifyArtistIdEncoded = encodeURIComponent(info.spotify_artist_id);
  const urlPath = `/artistas/${artistNameEncoded}/${spotifyArtistIdEncoded}`;
  window.location.href = `${window.location.origin}${urlPath}`;
};

export const handleVenueClick = (info) => {
  const venueEncoded = encodeURIComponent(info.concert_place.replace(/ /g, '-'));
  const urlPath = `/escenarios/${venueEncoded}`;
  window.location.href = `${window.location.origin}${urlPath}`;
};

export const handleCityClick = (info) => {
  const cityEncoded = encodeURIComponent(info.city.replace(/ /g, '-'));
  const urlPath = `/ciudades/${cityEncoded}`;
  window.location.href = `${window.location.origin}${urlPath}`;

};

export const handleFestivalClick = (festi) => {
  const festiEncoded = encodeURIComponent(festi.festi_name.replace(/ /g, '-'));
  const urlPath = `/festivales/${festiEncoded}`;
  window.location.href = `${window.location.origin}${urlPath}`;

};

export const handleDateClick = (info) => {
  const dateEncoded = encodeURIComponent(info.userDate.replace(/ /g, '-'));
  const urlPath = `/fechas/${dateEncoded}`;
  window.location.href = `${window.location.origin}${urlPath}`;
};

export const handleStyleClick = (info) => {
  const dateEncoded = encodeURIComponent(info.userStyle.replace(/ /g, '-'));
  const urlPath = `/estilos/${dateEncoded}`;
  window.location.href = `${window.location.origin}${urlPath}`;
};

export const handleSearchClick = () => {
  window.location.href = `${window.location.origin}/busqueda`;
};


export const handleCloseButton = () => {
  window.location.href = `${window.location.origin}`;
};

export const handleLoginPage = () => {
  window.location.href = `${window.location.origin}/login`;
}

export const handleFestiPage = () => {
  window.location.href = `${window.location.origin}/festivales`;
}

export const handleEventhioButton = () => {
  window.location.href = `${window.location.origin}`;
};

export const handleUserClick = () => {
  window.location.href = `${window.location.origin}/usuario`;
};

export const handleCitySelect = (city) => {
  window.location.href = `/${city}`; // Navigate to the selected city
};

export const handlePolicyClick = () => {
  window.location.href = `${window.location.origin}/privacidad`;
};

export const openInNewTab = (url) => {
  if (url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }
};