import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import "react-calendar/dist/Calendar.css";
import SEO from "../../functions/3_SEO.js";
import {
    formatDate,
    formatDateformTimestamp,
    convertTimestampToDateStr,
} from "../../functions/0_side_functions.js";
import {
    handleCloseButton,
    handleCityClick,
    handleVenueClick,
    handleArtistClick,
    openInNewTab
} from "../../functions/1_handle_clicks.js";
import { handleFestivalClick } from "../../functions/1_handle_clicks.js";

function AllFestivals() {
    const [festiAll, setFestiAll] = useState([]);
    const [festiAllArtists, setFestiAllArtists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedSort, setSelectedSort] = useState("Fecha"); // State to manage selected sort

    const type_of_query = "all_festivals";
    const queryString = new URLSearchParams({
        type_of_query,
    }).toString();

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchEventData = async () => {
            try {
                setLoading(true);
                const queryStringData = new URLSearchParams(queryString).toString();
                const url = `https://cngtijyvn1.execute-api.eu-north-1.amazonaws.com/default/9_Festivals_EU_NORTH_1?${queryStringData}`;
                const response = await fetch(url, { signal });
                const data = await response.json();
                setFestiAll(JSON.parse(data.festis_titles));
                setFestiAllArtists(JSON.parse(data.festis_artists));
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };
        fetchEventData();
        return () => {
            abortController.abort();
        };
    }, [queryString]);

    const handleSortSelection = (sortType) => {
        setSelectedSort(sortType);
        // You can add more logic here to handle sorting/filtering based on the selected type.
    };

    if (loading) {
        return <div className="loadingScreen"></div>;
    } else {
        return (
            <div className="festi__BigWrapper">
                <div className="festiPage__Wrap">
                    <div className="homepage__frontPageTextBlackWrap">
                        <div className="homepage__frontPageTextBlack">
                            Todos los festivales,
                        </div>
                        <div className="homepage__frontPageTextBlack">
                            para ti
                        </div>
                        <div className="festiPage__Buttons_WrapWrap">
                            <div className="festiPage__Buttons_Wrap">
                                <div
                                    className={`festiPage__Buttons_Sort_Wrap ${selectedSort === "Para ti" ? "festiPage__Buttons_Sort_Wrap_S" : ""}`}
                                    onClick={() => handleSortSelection("Para ti")}
                                >
                                    <div className="festiPage__Buttons_4U_SVG"></div>
                                    <div className="festiPage__Buttons_Sort">Para ti</div>
                                </div>
                                <div
                                    className={`festiPage__Buttons_Sort_Wrap ${selectedSort === "Fecha" ? "festiPage__Buttons_Sort_Wrap_S" : ""}`}
                                    onClick={() => handleSortSelection("Fecha")}
                                >
                                    <div className="festiPage__Buttons_Sort_SVG"></div>
                                    <div className="festiPage__Buttons_Sort">Fecha</div>
                                </div>
                                <div
                                    className={`festiPage__Buttons_Sort_Wrap ${selectedSort === "Precio" ? "festiPage__Buttons_Sort_Wrap_S" : ""}`}
                                    onClick={() => handleSortSelection("Precio")}
                                >
                                    <div className="festiPage__Buttons_Sort_SVG"></div>
                                    <div className="festiPage__Buttons_Sort">Precio</div>
                                </div>
                                <div
                                    className={`festiPage__Buttons_Sort_Wrap ${selectedSort === "Festivales este mes" ? "festiPage__Buttons_Sort_Wrap_S" : ""}`}
                                    onClick={() => handleSortSelection("Festivales este mes")}
                                >
                                    <div className="festiPage__Buttons_Date">Festivales este mes</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="festiPage__FestiWrap">
                        {festiAll.length > 0 ? (
                            festiAll.map((festi) => {
                                const divStyle = {
                                    backgroundImage: `url(${festi.festi_image})`
                                };
                                const divStyle2 = {
                                    backgroundImage: `linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(${festi.festi_image})`
                                };
                                const filteredArtists = festiAllArtists
                                    .filter(artist => artist.festi_name === festi.festi_name)

                                return (
                                    <div key={festi.festi_name} className="festiPage__Item">
                                        <div className="festiPage__ItemTitle">{festi.festi_name}</div>
                                        <div className="festiPage__festiPageInfoWrap">
                                            {festi.festi_local_date_start !== festi.festi_local_date_end ? (
                                                <div className="festiPage__ItemElement">
                                                    Del {formatDate(formatDateformTimestamp(festi.festi_local_date_start))} al {formatDate(formatDateformTimestamp(festi.festi_local_date_end))}
                                                </div>
                                            ) : (
                                                <div className="festiPage__ItemElement">
                                                    {formatDate(formatDateformTimestamp(festi.festi_local_date_start))}
                                                </div>
                                            )}
                                            <div className="festiPage__ItemElement2">{festi.festi_city}</div>
                                        </div>


                                        <div style={divStyle2} className="festiPage__Item_PhotoBG">
                                            <div style={divStyle} className="festiPage__Item_Photo"></div>
                                        </div>
                                        <div className="festiPage__ItemTitleQuien">¿Quién asistirá?</div>

                                        <div>
                                            {filteredArtists.length > 0 && (
                                                <>
                                                    <div className="concert__rowWrapFesti">

                                                        <div
                                                            className="concert__SimilarRowArtist"
                                                            key="event_artists"
                                                        >
                                                            {filteredArtists.map((artist, index) => {
                                                                const divStyle = {
                                                                    backgroundImage: `url(${artist.spotify_artist_photo})`,
                                                                    backgroundSize: "cover",
                                                                };
                                                                return (
                                                                    <div key={artist.spotify_artist_id + index}>
                                                                        <div
                                                                            className="concert__artistSimilarConcert"
                                                                            style={divStyle}
                                                                            onClick={() => handleArtistClick(artist)}
                                                                        ></div>
                                                                        <div className="concert__Subbox">
                                                                            <div className="festi__SimilarArtistName">
                                                                                {artist.spotify_artist_name}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                            )}





                                        </div>

                                        <div className="festi__Boton_Wrap2">
                                            <div className="festi__BotonPage">
                                                <div onClick={() => handleFestivalClick(festi)}
                                                >IR AL FESTIVAL</div>
                                                <div className="festi__Boton_Arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                );

                            })
                        ) : (
                            <div className="blog__Wrapper"></div>
                        )}

                    </div>
                </div>
            </div>
        );

    }
}



function SpecificFestivals() {
    const [loading, setLoading] = useState(true);
    const [specificFesti, setSpecificFesti] = useState([]);
    const [festiArtists, setFestiArtists] = useState([]);
    const type_of_query = "specific_festivals";
    const { festival_name } = useParams();
    const queryString = new URLSearchParams({
        festival_name,
        type_of_query,
    }).toString();

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchEventData = async () => {
            try {
                setLoading(true);
                const queryStringData = new URLSearchParams(queryString).toString();
                const url = `https://cngtijyvn1.execute-api.eu-north-1.amazonaws.com/default/9_Festivals_EU_NORTH_1?${queryStringData}`;
                console.log(url)
                const response = await fetch(url, { signal });
                const data = await response.json();
                setSpecificFesti(JSON.parse(data.specific_festivals)[0]);
                setFestiArtists(JSON.parse(data.specific_festivals_artists));
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };
        fetchEventData();
        return () => {
            abortController.abort();
        };
    }, [queryString]);

    if (loading) {
        return <div className="loadingScreen"></div>;
    } else {
        const divStyle = {
            backgroundImage: `url(${specificFesti.festi_image})`,
            aspectRatio: "16 / 9",
        };
        return (
            <div className="pageWrapper">
                <div className="concert__TopSpace"></div>
                <div className="pageWrapper_SecondLevel">
                    <div className="concert_BackBlack" style={divStyle}></div>

                    <div className="concert__artistPhoto" style={divStyle}>
                        <div className="closeButtonWrap" onClick={() => handleCloseButton()}>
                            <div className="closeButton"></div>
                        </div>
                    </div>
                    <div className="concertInfo__Wrapper">
                        <h1 className="artist_Name">
                            {specificFesti.festi_name}
                        </h1>
                        <div className="concertInfo__Wrapper_Like">
                            <div className="concertInfo__Wrapper_Like_Wrap">
                                <div className="city_Name" onClick={() => handleCityClick({ city: specificFesti.festi_city })}>
                                    {specificFesti.festi_city}
                                </div>
                                <div className="venue_Name" onClick={() => handleVenueClick({ concert_place: specificFesti.festi_place })}>
                                    {specificFesti.festi_place}
                                </div>
                                <div className="date_Name">
                                    {specificFesti.festi_local_date_start === specificFesti.festi_local_date_end ? (
                                        formatDate(convertTimestampToDateStr(specificFesti.festi_local_date_start))
                                    ) : (
                                        `Del ${formatDate(convertTimestampToDateStr(specificFesti.festi_local_date_start))} al ${formatDate(convertTimestampToDateStr(specificFesti.festi_local_date_end))}`
                                    )}
                                </div>
                                <div className="concert__InfoMoney">
                                    {specificFesti.festi_min_price === 0 && specificFesti.festi_max_price === 0
                                        ? "No hay precio disponible"
                                        : specificFesti.festi_min_price === specificFesti.festi_max_price
                                            ? `${specificFesti.festi_min_price}€`
                                            : `${specificFesti.festi_min_price}€ - ${specificFesti.festi_max_price}€`}
                                </div>
                            </div>

                        </div>
                        <div className="festi__Boton_Wrap">
                            <div className="festi__Boton">

                                <div onClick={() => openInNewTab(specificFesti.festi_url)}>COMPRAR ENTRADAS</div>
                                <div className="festi__Boton_Arrow"></div>
                            </div>
                        </div>
                        <div className="festival__WhiteWrap">

                            {specificFesti.festi_description &&
                                specificFesti.festi_description !== "" ? (
                                <div className="concert__Info">
                                    <h1 className="festi__Title">
                                        Más acerca de{" "}
                                        {specificFesti.festi_name}
                                    </h1>
                                    <div className="concert__Info_Text">
                                        <div>
                                            {specificFesti.festi_description.split('\\n\\n').map((paragraph, index) => (
                                                <div key={index} className="festi__Para">{paragraph}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ height: "0px" }}></div>
                            )}
                        </div>
                        <div className="festival__WhiteWrap">
                            <h1 className="festi__Title">
                                Artistas confirmados
                            </h1>
                            <div className="concert__rowWrap">
                                <div
                                    className="festi__SimilarRowArtist"
                                    key="festi_artists"
                                >
                                    {festiArtists.map((festi_artist, index) => {
                                        const divStyle = {
                                            backgroundImage: `url(${festi_artist.spotify_artist_photo})`,
                                            backgroundSize: "cover",
                                        };
                                        return (
                                            <div key={festi_artist.spotify_artist_id + index}>
                                                <div
                                                    className="concert__artistSimilarConcert"
                                                    style={divStyle}
                                                    onClick={() => handleArtistClick(festi_artist)}
                                                ></div>
                                                <div className="concert__Subbox">
                                                    <div className="festi__SimilarArtistName">
                                                        {festi_artist.spotify_artist_name}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { AllFestivals, SpecificFestivals };
