import React, { useState } from "react";
import {
  handleEventhioButton,
  handleSearchClick,
  handleUserClick,
  handleLoginPage,
  handleFestiPage
} from "../../functions/1_handle_clicks";
import { useAuth } from "../../functions/2_auth_context";
import SideMenu from "../11_Sidemenu/11_Sidemenu";

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { userInfo } = useAuth();
  const spotifyPhoto = userInfo ? userInfo.picture : null;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    document.body.style.overflow = menuOpen ? "unset" : "hidden";
  };

  return (
    <nav className="navbar">
      <SideMenu isOpen={menuOpen} toggleMenu={toggleMenu}></SideMenu>
      <div className="navbarTitleWrapper">
        <div className="navbarTitle" onClick={() => handleEventhioButton()}>
        </div>
        <div className="sp_button_wrapper">
          <div className="navbar__NoriaSVG" onClick={() => handleFestiPage()}></div>
          <div
            className="glassWhitePC"
            onClick={() => handleSearchClick()}
          >
            <div className="glassWhite" onClick={() => handleSearchClick()}></div>
            <div className="glassWhiteText"> Buscador de conciertos</div>
          </div>


          {spotifyPhoto ? (
            <div
              className={spotifyPhoto === 'noPhoto' ? 'userPhotoNoName' : 'userPhoto'}
              style={spotifyPhoto !== 'noPhoto' ? { backgroundImage: `url(${spotifyPhoto})` } : {}}
              onClick={handleUserClick}
            >
              {spotifyPhoto === 'noPhoto' && userInfo.name ? userInfo.name.charAt(0) : null}
            </div>
          ) : (
            <div className="sp_logo" onClick={handleLoginPage}></div>
          )}

          <div
            className={`hamburger-menu ${menuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
