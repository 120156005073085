import React from "react";
import { useAuth } from "../../functions/2_auth_context";

function LoginPage() {
  const { handleLogin_Google, handleLogin_Spotify } = useAuth();

  return (
    <div style={{ backgroundColor: "white", minHeight: "calc(100vh - var(--heightNavbar))" }}>
      <div className="loginModal">
        <div className="login__LogoGap"></div>
        <div className="login__Logo"></div>
        <div className="login__Welcometext">
          Te damos la bienvenida a Eventhio
        </div>
        <div className="login__Google" onClick={handleLogin_Google}>
          <div className="login__GoogleLogo"></div>
          <div className="login__text">Continuar con Google</div>
        </div>
        <div className="login__Spotify" onClick={handleLogin_Spotify}>
          <div className="login__SpotifyLogo"></div>
          <div className="login__text">Continuar con Spotify</div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
