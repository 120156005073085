import React, { useRef, useState } from 'react';
import { GoogleMap, LoadScript, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: 'calc(100% - 20px)',
  margin: '0 auto',
  height: '300px'
};

const GoogleMapComponent = ({ location }) => {
  const mapRef = useRef(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [placeDetails, setPlaceDetails] = useState(null);

  const handleLoad = (map) => {
    mapRef.current = map;
    const service = new window.google.maps.places.PlacesService(map);

    const request = {
      query: location,
      fields: ['name', 'geometry', 'formatted_address', 'place_id'],
    };

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
        const place = results[0];
        if (place.geometry && place.geometry.location) {
          map.setCenter(place.geometry.location);
          setSelectedPlace(place.geometry.location);
          getPlaceDetails(place.place_id);
        }
      }
    });
  };

  const getPlaceDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(mapRef.current);

    const request = {
      placeId: placeId,
      fields: ['name', 'formatted_address', 'url']
    };

    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaceDetails(place);
      }
    });
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDEv8qY_uJdL-VOHvHgIS2Drp3O_HfUc7g" libraries={['places']}>
      <GoogleMap
        onLoad={handleLoad}
        mapContainerStyle={containerStyle}
        zoom={15}
      >
        {selectedPlace && placeDetails && (
          <InfoWindow position={selectedPlace} onCloseClick={() => setSelectedPlace(null)}>
            <div>
              <p>{placeDetails.name}</p>
              {placeDetails.url && (
                <p>
                  <a href={placeDetails.url} target="_blank" rel="noopener noreferrer">
                    Abrir en Google Maps
                  </a>
                </p>
              )}
              <p>{placeDetails.formatted_address}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;
