import React, { useState, useEffect, useRef } from "react";
import {
  formatDate,
  formatDateformTimestamp,
} from "../../functions/0_side_functions.js";
import {
  handleArtistClick,
  handleSearchClick,
  handleConcertClick
} from "../../functions/1_handle_clicks";
import { useAuth } from "../../functions/2_auth_context";

function UserDetails() {
  const { handleLogout } = useAuth();
  const [topUserData, setTopUserRowData] = useState([]);
  const [topUserConcert, setTopConcertData] = useState([]);
  const [loading, setLoading] = useState(true);

  const id = JSON.parse(localStorage.getItem("userInfo")).eventhio_id;
  const name = JSON.parse(localStorage.getItem("userInfo")).name;
  // const photo = JSON.parse(localStorage.getItem("userInfo_Google")).photo;


  const queryString = new URLSearchParams({
    sp_user_id: id,
    type_of_query: "user_artists_info",
  }).toString();

  useEffect(() => {
    const abortController = new AbortController();

    const fetchEventData = async () => {
      try {
        setLoading(true);
        const queryStringData = new URLSearchParams(queryString).toString();
        const url = `https://gkwimrhil6.execute-api.eu-north-1.amazonaws.com/default/5_User_data_EU_NORTH_1?${queryStringData}`;
        const response = await fetch(url);
        const data = await response.json();
        const artist_data = JSON.parse(data.artists);
        const concert_data = JSON.parse(data.concerts);
        setTopUserRowData(artist_data);
        setTopConcertData(concert_data);
      } catch (error) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userInfo_Google");
        window.location.href = `${window.location.origin}/inicio`;
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();

    return () => {
      abortController.abort(); // Cleanup function to cancel the fetch request
    };
  }, [queryString]);

  if (loading) {
    return <div className="loadingScreen"></div>;
  } else {

    return (
      <div className="info__Wrapper">
        <div className="userNamelogOutWrap">
          <div className="userName">Bienvenid@ {name}</div>
          <div className="logOut" onClick={() => handleLogout()}>
            Cerrar sesión
          </div>
        </div>
        <h2 className="info__Title">Conciertos que te interesan</h2>
        <div>
          {topUserConcert.length > 0 ? (

            <div className="info__concertContainer">
              {topUserConcert.map((concert, index) => {
                    const divStyle = {
                      backgroundImage: `url(${concert.concert_image})`,
                    };
                    const divStyle2 = {
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(${concert.concert_image})`,
                      backgroundSize: "cover",
                      borderRadius: "4px",
                    };
                    return (
                      <div key={concert.concert_id + index}>
                        <div style={divStyle2}>
                        <div
                          className="eventSimilarInfo"
                          style={divStyle}
                          onClick={() => handleConcertClick(concert)}
                        ></div>
                        </div>
                        <div className="search__concertInfoWrap">
                          <div className="search__concertName">
                            {concert.spotify_artist_name}
                          </div>
                          <div className="search__concertDate">
                            {concert.city}
                          </div>
                          <div className="search__concertDate">
                            {formatDate(
                              formatDateformTimestamp(concert.local_date_start)
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
            
            
          ) : (
            <div className="info__Wrap" >
              <div className="newuser__searchArtistWrap" onClick={() => handleSearchClick()}>
                <div className="newuser__searchPhotoArtistSVG"></div>
                <div className="newuser__searchTitle">
                  Añade conciertos que te interesen
                </div>
              </div>
            </div>
          )}
        </div>
        <h2 className="info__Title">Artistas seguidos</h2>
        {topUserData &&
          Array.isArray(topUserData) &&
          (topUserData.length > 1 ||
            (topUserData.length === 1 &&
              topUserData[0].spotify_artist_id !== null)) ? (
          <div className="info__Wrap">
            {topUserData.map((artist, index) => (
              <div key={artist.spotify_artist_name}>
                <div
                  className="newuser__searchArtistWrapWrap"
                  onClick={() => handleArtistClick(artist)}
                >
                  <div className="newuser__searchArtistWrap">

                    <div
                      className="newuser__searchPhotoArtist"
                      style={{
                        backgroundImage: `url(${artist.spotify_artist_photo})`,
                      }}
                    ></div>
                    <div className="newuser__searchTitle">
                      {artist.spotify_artist_name}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div
              className="newuser__searchArtistWrapWrap"
              style={{ paddingTop: "0px" }}
              onClick={() => handleSearchClick()}
            >
              <div className="newuser__searchArtistWrap">
                <div className="newuser__searchPhotoArtistSVG"></div>
                <div className="newuser__searchTitle">
                  Añade artistas que te gusten
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="info__Wrap">
            <div
              className="newuser__searchArtistWrapWrap"
              style={{ paddingTop: "0px" }}
              onClick={() => handleSearchClick()}
            >
              <div className="newuser__searchArtistWrap">
                <div className="newuser__searchPhotoArtistSVG"></div>
                <div className="newuser__searchTitle">
                  Añade artistas que te gusten
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ height: "20px" }}></div>
      </div>
    );
  }
}
export default UserDetails;