import React, { useState, useEffect, useRef } from "react";
import {
  handleConcertClick,
  handleCloseButton,
} from "../../functions/1_handle_clicks.js";
import { useParams } from "react-router-dom";
import Calendar from "react-calendar";
import {
  formatDate,
  convertTimestampToDateStr,
} from "../../functions/0_side_functions.js";
import "react-calendar/dist/Calendar.css";
import SEO from "../../functions/3_SEO.js";
import { v4 as uuidv4 } from 'uuid';

function CityDetails() {
  const [cityAll, setCityAll] = useState({});
  const [loading, setLoading] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showArtist, setShowArtist] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [artistFilter, setArtistFilter] = useState("");
  const inputRef = useRef(null);
  const { city } = useParams();
  const type_of_query = "Cities";

  const toggleCalendar = () => {
    setShowCalendar((currentShowCalendar) => {
      if (!currentShowCalendar && showArtist) {
        setShowArtist(false);
      }
      return !currentShowCalendar;
    });
  };

  const toggleArtists = () => {
    setShowArtist((prevShowArtist) => {
      if (prevShowArtist) {
        if (inputRef.current) inputRef.current.blur();
      }
      return !prevShowArtist;
    });
  };

  const handleDateChange = (value) => {
    setShowCalendar(false);
    setSelectedDates(value);
  };

  const formatDateRange = (dates) => {
    if (!dates) return "Todas las fechas";
    if (Array.isArray(dates)) {
      return `${formatDate(
        convertTimestampToDateStr(dates[0])
      )} -> ${formatDate(convertTimestampToDateStr(dates[1]))}`;
    }
    return formatDate(convertTimestampToDateStr(dates));
  };

  const queryString = new URLSearchParams({
    city,
    type_of_query,
  }).toString();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchEventData = async () => {
      try {
        setLoading(true);
        const queryStringData = new URLSearchParams(queryString).toString();
        const url = `https://68gnhmjcw2.execute-api.eu-north-1.amazonaws.com/default/1_Complete_modal_EU_NORTH_1?${queryStringData}`;
        const response = await fetch(url, { signal });
        const data = await response.json();
        const data_city_all = JSON.parse(data.city_all_concerts);
        setCityAll(data_city_all);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();

    return () => {
      abortController.abort();
    };
  }, [queryString]);

  useEffect(() => {
    const saveInfo = async () => {
      let cookieId = localStorage.getItem('cookieId');
      if (!cookieId) {
        cookieId = uuidv4();
        localStorage.setItem('cookieId', cookieId);
      }

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let userId = userInfo && userInfo.eventhio_id ? userInfo.eventhio_id : null;

      const payload = {
        type_of_query: "PostInfo",
        url: window.location.href,
        user_id: userId,
        ev_cookie_id: cookieId,
      };
      try {
        const queryStringData = new URLSearchParams(payload).toString();
        const url = `https://6l04gpt4e4.execute-api.eu-north-1.amazonaws.com/default/8_Database_post_delete_EU_NORTH_1?${queryStringData}`;
        await fetch(url);
      } catch (error) {
      }
    };

    saveInfo();
  }, []);

  const handleArtistClick = (artist) => {
    if (
      !selectedArtists.some(
        (item) => item.spotify_artist_name === artist.spotify_artist_name
      )
    ) {
      setSelectedArtists([...selectedArtists, artist]);
    }
    setArtistFilter("");
    setShowArtist(false); // Close the dropdown upon artist selection
  };

  const handleDivClick = () => {
    inputRef.current.focus();
    if (!showArtist) {
      setShowArtist(true);
    }
  };

  const clearArtists = () => {
    setSelectedArtists([]);
    setArtistFilter("");
    setShowArtist(false);
    if (inputRef.current) inputRef.current.blur();
  };

  const clearCalendar = () => {
    setSelectedDates(null); // Reset the selected dates

    // Close the calendar if it's open, otherwise leave its state unchanged
    if (showCalendar) {
      setShowCalendar(false);
    } else {
      setShowCalendar(false);
    }
  };

  if (loading) {
    return <div className="loadingScreen"></div>;
  } else {
    console.log(`../../img/imagenes/tm_city/${cityAll[0].city_image}`)
    const divStyle = {
      backgroundSize: "cover",
      aspectRatio: "16 / 9",
      backgroundImage: cityAll[0]?.city_image
        ? `url(${require(`../../img/imagenes/tm_city/${cityAll[0].city_image}`)})`
        : `url(${require('../../img/imagenes/tm_city/city.png')})`,
    };

    const uniqueArtistAll = cityAll
      .filter((artist) => artist.spotify_artist_name) // Filter out empty names
      .reduce((unique, artist) => {
        if (
          !unique.some(
            (item) => item.spotify_artist_name === artist.spotify_artist_name
          )
        ) {
          unique.push(artist); // Add only unique names
        }
        return unique;
      }, [])
      .sort((a, b) =>
        a.spotify_artist_name.localeCompare(b.spotify_artist_name)
      );

    const removeArtist = (artistName) => {
      setSelectedArtists(
        selectedArtists.filter(
          (artist) => artist.spotify_artist_name !== artistName
        )
      );
    };

    const displayableArtists = uniqueArtistAll.filter(
      (artist) =>
        !selectedArtists.some(
          (selected) =>
            selected.spotify_artist_name === artist.spotify_artist_name
        )
    );

    const normalizedFilter = artistFilter
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    const filteredArtists = displayableArtists.filter((artist) =>
      artist.spotify_artist_name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .includes(normalizedFilter)
    );

    /* SEO ELEMENTS */
    const seoTitle = `Conciertos en ${cityAll[0].city.replace("-", " ")} | Eventhio`;
    const seoDescription = `Encuentra todos los conciertos en ${cityAll[0].city.replace("-", " ")}. ¡Compra ya tus entradas!`;
    const seoUrl = `https://www.eventhio.com/ciudades/${cityAll[0].city.replace("-", " ")}`;
    // const seoPhoto = `url(${artistPhoto[0].spotify_artist_photo})`;

    return (
      <>
        <SEO
          title={seoTitle}
          description={seoDescription}
          url={seoUrl}
          image=''
        />
        <div className="pageWrapper">
          <div className="concert__TopSpace"></div>
          <div className="pageWrapper_SecondLevel">
            <div className="concert_BackBlack" style={divStyle}></div>
            <div className="concert__artistPhoto" style={divStyle}>
              <div className="closeButtonWrap" onClick={() => handleCloseButton()}>
                <div className="closeButton"></div>
              </div>
            </div>
            <div className="concertInfo__Wrapper">
              <div className="general__TopSpace"></div>
              <h1 className="artist_Name">
                {cityAll[0].city.replace("-", " ")}
              </h1>
              <div className="general__TopSpace"></div>
              <div className="city__WrapperSelections">
                <div className="city__Input_Calendar">
                  <div className="city__opcionesSearch">
                    <div className="city__dateLogo"></div>
                    <div className="city__datePlaceholder" onClick={toggleCalendar}>
                      {formatDateRange(selectedDates)}
                    </div>
                    <div className="city__clearInput" onClick={clearCalendar}></div>
                  </div>
                  <div className="city__Input_CalendarWrap">
                    {showCalendar && (
                      <Calendar
                        onChange={handleDateChange}
                        selectRange={true}
                        returnValue="range"
                      />
                    )}
                  </div>
                </div>
                <div className="city__Input_Artists">
                  <div className="city__opcionesSearch_2">
                    <div className="city__artLogo"></div>
                    <div className="city__artistTagWrap" onClick={handleDivClick}>
                      {selectedArtists.map((artist) => (
                        <div
                          key={artist.spotify_artist_name}
                          className="city__artistTag"
                        >
                          <div
                            className="city__tagArtistPhoto"
                            style={{
                              backgroundImage: `url(${artist.spotify_artist_photo})`,
                            }}
                          ></div>
                          <div className="city__tagArtistName">
                            {artist.spotify_artist_name}
                          </div>
                          <div
                            className="city__tagClose"
                            onClick={() => removeArtist(artist.spotify_artist_name)}
                          ></div>
                        </div>
                      ))}
                      <input
                        ref={inputRef}
                        className={
                          selectedArtists.length > 0
                            ? "city__datePlaceholderSmall"
                            : "city__datePlaceholder"
                        }
                        placeholder={
                          selectedArtists.length === 0 ? "Todos los artistas" : ""
                        }
                        value={artistFilter}
                        onChange={(e) => setArtistFilter(e.target.value)}
                      ></input>
                    </div>

                    <div className="city__clearInput" onClick={clearArtists}></div>
                    <div
                      className={showArtist ? "city__dropdownUp" : "city__dropdown"}
                      onClick={toggleArtists}
                    ></div>
                  </div>
                  <div className="city__Input_ArtistsWrap">

                    {showArtist && (
                      <div className="city__artistSearch">
                        {filteredArtists.map((artist, index) => (
                          <div
                            key={artist.spotify_artist_name}
                            className="city__artistRow"
                            onClick={() => handleArtistClick(artist)}
                          >
                            <div
                              className="city__artistImage"
                              style={{
                                backgroundImage: `url(${artist.spotify_artist_photo})`,
                              }}
                            ></div>
                            <div className="city__artistName">
                              {artist.spotify_artist_name}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
            <div className="city__rowWrap">
              {cityAll
                .filter((concert) => {
                  // Filter by artist if any are selected
                  const artistMatch =
                    selectedArtists.length === 0 ||
                    selectedArtists.some(
                      (artist) =>
                        artist.spotify_artist_name === concert.spotify_artist_name
                    );

                  // Filter by date range if dates are selected
                  let dateMatch = true;
                  if (selectedDates) {
                    const concertDate = new Date(
                      convertTimestampToDateStr(concert.local_date_start)
                    ).getTime();
                    const startDate = new Date(
                      convertTimestampToDateStr(selectedDates[0])
                    ).getTime();
                    const endDate =
                      selectedDates.length > 1
                        ? new Date(
                          convertTimestampToDateStr(selectedDates[1])
                        ).getTime()
                        : startDate;
                    dateMatch =
                      concertDate >= startDate && concertDate <= endDate;
                  }

                  return artistMatch && dateMatch;
                })
                .length === 0 ? (
                <div className='no-data-message'>
                  <div className="concert__NoconcertWrap">
                    <div className="concert__NoconcertText">
                      No existen conciertos con esas características.
                    </div>
                  </div>
                </div>
              ) : (
                cityAll
                  .filter((concert) => {
                    // Apply the same filtering logic
                    const artistMatch =
                      selectedArtists.length === 0 ||
                      selectedArtists.some(
                        (artist) =>
                          artist.spotify_artist_name === concert.spotify_artist_name
                      );

                    let dateMatch = true;
                    if (selectedDates) {
                      const concertDate = new Date(
                        convertTimestampToDateStr(concert.local_date_start)
                      ).getTime();
                      const startDate = new Date(
                        convertTimestampToDateStr(selectedDates[0])
                      ).getTime();
                      const endDate =
                        selectedDates.length > 1
                          ? new Date(
                            convertTimestampToDateStr(selectedDates[1])
                          ).getTime()
                          : startDate;
                      dateMatch =
                        concertDate >= startDate && concertDate <= endDate;
                    }

                    return artistMatch && dateMatch;
                  })
                  .map((concert, index) => (
                    <div
                      className="city__row"
                      onClick={() => handleConcertClick(concert)}
                      key={concert.concert_id}
                      
                    >
                      <div style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.85), rgba(0,0,0,0.85)), url(${concert.concert_image})`,
                      backgroundSize: "cover",
                      borderRadius: "4px",
                    }}>
                      <div
                        className="city__photo"
                        style={{
                          backgroundImage: `url(${concert.concert_image})`,
                        }}
                      ></div>
                      </div>
                      <div className="search__concertInfoWrap">
                        <div className="search__concertName">
                          {concert.spotify_artist_name}
                        </div>
                        <div className="search__concertPlace">
                          {concert.concert_place}
                        </div>
                        <div className="search__concertDate">
                          {formatDate(
                            convertTimestampToDateStr(concert.local_date_start)
                          )}
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>


          </div>
        </div>
      </>
    );
  }
}

export default CityDetails;

