import React, { createContext, useContext, useState, useEffect } from "react";
const AuthContext = createContext();

// Google Auth Config
const GOOGLE_CLIENT_ID = "518313764662-othi410j9vf0arcqgdsbi8r2epu2j40t.apps.googleusercontent.com";
const GOOGLE_REDIRECT_URI = "https://www.eventhio.com";
const GOOGLE_AUTH_ENDPOINT = "https://accounts.google.com/o/oauth2/v2/auth";
const GOOGLE_RESPONSE_TYPE = "token";
const GOOGLE_SCOPE = "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";

// Spotify Auth Config
const SPOTIFY_CLIENT_ID = "17db8b93ba8140fe97bbc8186b9c3c2b";
const SPOTIFY_REDIRECT_URI = "https://www.eventhio.com";
const SPOTIFY_AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
const SPOTIFY_RESPONSE_TYPE = "token";
const SPOTIFY_SCOPE = "user-read-private user-read-email user-top-read";

// Login Handlers
const handleLogin_Google = () => {
  localStorage.setItem("authContext", "Google");
  const authUrl = `${GOOGLE_AUTH_ENDPOINT}?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    GOOGLE_REDIRECT_URI
  )}&scope=${encodeURIComponent(
    GOOGLE_SCOPE
  )}&response_type=${GOOGLE_RESPONSE_TYPE}&show_dialog=true`;
  window.location.href = authUrl;
};

const handleLogin_Spotify = () => {
  localStorage.setItem("authContext", "Spotify");
  const authUrl = `${SPOTIFY_AUTH_ENDPOINT}?client_id=${SPOTIFY_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    SPOTIFY_REDIRECT_URI
  )}&scope=${encodeURIComponent(
    SPOTIFY_SCOPE
  )}&response_type=${SPOTIFY_RESPONSE_TYPE}&show_dialog=true`;
  window.location.href = authUrl;
};

// Logout Handler
const handleLogout = () => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("authToken_Google");
  localStorage.removeItem("authToken_Spotify");
  localStorage.removeItem("authContext");
  window.location.href = `${window.location.origin}`;
};

// Fetch User Info
const fetchGoogleUserInfo = async (token, setIsLoadingInfo) => {
  try {
    const response = await fetch("https://www.googleapis.com/oauth2/v1/userinfo?alt=json", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setIsLoadingInfo(false);
    if (response.ok) { setIsLoadingInfo(true) }
    // Si el usuario es nuevo hay que añadirlo
    const payload = {
      type_of_query: 'AddGoogleData',
      user_id: 'Google_'+ data.email,
      user_name: data.name,
      user_email: data.email,
      user_picture: data.picture
    };
    try {
      const queryStringDataAddGoogle = new URLSearchParams(payload).toString();
      const url_add_google = `https://6l04gpt4e4.execute-api.eu-north-1.amazonaws.com/default/8_Database_post_delete_EU_NORTH_1?${queryStringDataAddGoogle}`;
      const response_add_google = await fetch(url_add_google);
      if (response_add_google.ok) {
        setIsLoadingInfo(false)
      }
    } catch (error) { }
    return { context: "Google", eventhio_id: "Google_" + data.email, ...data };
  } catch (error) { return null }
};

const fetchSpotifyUserInfo = async (token, setIsLoadingInfo) => {
  try {
    const response = await fetch("https://api.spotify.com/v1/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setIsLoadingInfo(false);
    if (response.ok) {
      setIsLoadingInfo(true);

      // Aquí, si el usuario es nuevo hay que descargar sus datos, artistas más gustados y la información de esos artistas (que no esté ya)
      const payload = {
        type_of_query: 'AddSpotifyData',
        user_id: token,
      };
      try {
        const queryStringDataAddSpotify = new URLSearchParams(payload).toString();
        const url_add_spotify = `https://6l04gpt4e4.execute-api.eu-north-1.amazonaws.com/default/8_Database_post_delete_EU_NORTH_1?${queryStringDataAddSpotify}`;
        console.log(url_add_spotify)
        const response_add_spotify = await fetch(url_add_spotify);
        if (response_add_spotify.ok) {
          setIsLoadingInfo(false)
        }
      } catch (error) {
        setIsLoadingInfo(false)
      }
      let photoUrl = `noPhoto`;
      try {
        if (data.images && Array.isArray(data.images)) {
          photoUrl = data.images.find(img => img.width === 300)?.url ||
            data.images.find(img => img.width === 64)?.url ||
            `noPhoto`;
        }
      } catch (error) {
        console.log(error)
      }
      return {
        context: "Spotify",
        authToken: token,
        id: data.id,
        eventhio_id: data.id,
        email: data.email,
        name: data.display_name,
        picture: photoUrl,
      };
    } else {
      handleLogout()
    }
  } catch (error) {
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const [authTokenGoogle, setAuthTokenGoogle] = useState(localStorage.getItem("authToken_Google"));
  const [authTokenSpotify, setAuthTokenSpotify] = useState(localStorage.getItem("authToken_Spotify"));
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem("userInfo")));
  const [isChecking, setIsChecking] = useState(true);
  const [isLoadingInfo, setIsLoadingInfo] = useState(false); // Add this line

  useEffect(() => {
    const extractTokenFromHash = (hash) => {
      const params = new URLSearchParams(hash.substring(1));
      return params.get("access_token");
    };

    const checkForToken = async (service) => {
      const hash = window.location.hash;
      if (hash) {
        const urlToken = extractTokenFromHash(hash);
        if (urlToken) {
          if (service === 'Google') {
            if (authTokenGoogle !== urlToken) {
              setAuthTokenGoogle(urlToken);
              localStorage.setItem("authToken_Google", urlToken);
              window.history.pushState({}, document.title, GOOGLE_REDIRECT_URI);
            }
          } else if (service === 'Spotify') {
            if (authTokenSpotify !== urlToken) {
              setAuthTokenSpotify(urlToken);
              localStorage.setItem("authToken_Spotify", urlToken);
              window.history.pushState({}, document.title, SPOTIFY_REDIRECT_URI);
            }
          }
        }
      }
    };

    const authContext = localStorage.getItem("authContext");

    if (authContext === 'Google' && !authTokenGoogle) {
      checkForToken('Google');
    } else if (authContext === 'Spotify' && !authTokenSpotify) {
      checkForToken('Spotify');
    }

    const fetchUserInfo = async (token, service) => {
      let data;
      if (service === 'Google') {
        data = await fetchGoogleUserInfo(token, setIsLoadingInfo);
      } else if (service === 'Spotify') {
        data = await fetchSpotifyUserInfo(token, setIsLoadingInfo);
      }

      if (data) {
        const newUserInfo = { ...userInfo, ...data };
        if (JSON.stringify(userInfo) !== JSON.stringify(newUserInfo)) {
          setUserInfo(newUserInfo);
          localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
        }
      }
    };

    if (authContext === 'Google' && authTokenGoogle && !userInfo?.google) {
      fetchUserInfo(authTokenGoogle, 'Google');
    }
    if (authContext === 'Spotify' && authTokenSpotify && !userInfo?.spotify) {
      fetchUserInfo(authTokenSpotify, 'Spotify');
    }

    setIsChecking(false);
  }, [authTokenGoogle, authTokenSpotify, userInfo]);

  const value = {
    isLoadingInfo,
    isChecking,
    handleLogin_Google,
    handleLogin_Spotify,
    handleLogout,
    userInfo,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}
