import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import "react-calendar/dist/Calendar.css";
import SEO from "../../functions/3_SEO.js";

function BlogDetails() {
    const [blogAll, setBlogAll] = useState([]);
    const [loading, setLoading] = useState(true);
    const [itemsToShow, setItemsToShow] = useState(6);

    const type_of_query = "blog_all";
    const queryString = new URLSearchParams({
        type_of_query,
    }).toString();

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchEventData = async () => {
            try {
                setLoading(true);
                const queryStringData = new URLSearchParams(queryString).toString();
                const url = `https://4ixx6c0ibf.execute-api.eu-north-1.amazonaws.com/default/7_Blog_EU_NORTH_1?${queryStringData}`;
                const response = await fetch(url, { signal });
                const data = await response.json();
                setBlogAll(JSON.parse(data.blog));
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };
        fetchEventData();
        return () => {
            abortController.abort();
        };
    }, [queryString]);

    const handleViewMore = () => {
        setItemsToShow(itemsToShow + 6);
    };

    const currentBlogEntries = blogAll.slice(0, itemsToShow);


    return (
        <div className="blog__Wrapper">
            <div className="blog__Gap"></div>
            <div className="blog__Text_Wrapper">
                {currentBlogEntries.length > 0 ? (
                    currentBlogEntries.map((blog) => {
                        const divStyle = {
                            backgroundImage: `url(${require(`../../img/imagenes/blog/${blog.blog_image}`)})`
                        };

                        return (
                            <div key={blog.blog_title} className="blog__Item">
                                <div className="blog__Photo" style={divStyle}></div>
                                <h1 className="blog__Title">{blog.blog_title}</h1>
                                <d1 className="blog__Subtitle">{blog.blog_subtitle}</d1>
                                <div className="blog__Leer">LEER MÁS</div>
                            </div>
                        );
                    })
                ) : (
                    <div className="blog__Wrapper"></div>
                )}
            </div>
            {itemsToShow < blogAll.length && (
                <div className="blog__VerMas">
                    <div onClick={handleViewMore}>VER MÁS ARTÍCULOS</div>
                </div>
            )}
        </div>
    );
}

export default BlogDetails;
