import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, url, image, contentType = 'website', themeColor = '#282222' }) => {
  
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <meta name="robots" content="index" />
      <meta name="google" content="notranslate" />
      <meta name="theme-color" content={themeColor} />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />

      {/* Open Graph Tags */}
      <meta property="og:site_name" content="Eventhio" />
      <meta property="og:type" content={contentType} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

export default SEO;
