import React, { useState, useEffect, useRef, useCallback } from "react";
import Modal from "react-modal";
import { debounce } from "lodash"; // Ensure lodash is installed
import { useAuth } from "../../functions/2_auth_context";

Modal.setAppElement("#root");

function ModalSelectSingers({ isOpenModal }) {
  const [artistData, setArtistData] = useState([]);
  const [artistDataSelected, setArtistDataSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const fetchAbortController = useRef(null);
  const [showFullWrap, setShowFullWrap] = useState(false);
  const { userInfo } = useAuth();
  const debouncedSearch = useCallback(
    debounce((query) => {
      if (fetchAbortController.current) {
        fetchAbortController.current.abort();
      }
      fetchAbortController.current = new AbortController();
      const signal = fetchAbortController.current.signal;
      const queryString = new URLSearchParams({ busqueda: query }).toString();
      const url = `https://ftgofl0xr9.execute-api.eu-north-1.amazonaws.com/default/6_Search_bar_EU_NORTH_1?${queryString}`;

      setLoading(true);
      fetch(url, { signal })
        .then((response) => response.json())
        .then((data) => {
          setArtistData(JSON.parse(data.artists));
          setShowFullWrap(true); // Display the fullWrap upon fetching data
        })
        .catch((error) => {
          if (error.name !== "AbortError") {
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const trimmedSearchInput = searchInput.trim();

    if (trimmedSearchInput) {
      debouncedSearch(trimmedSearchInput);
    }

    return () => {
      if (fetchAbortController.current) {
        fetchAbortController.current.abort();
      }
    };
  }, [searchInput, debouncedSearch]);

  const clearSearchInput = () => {
    setSearchInput("");
    setArtistData([]);
    setShowFullWrap(false); // Hide the fullWrap when clearing the input
  };

  const selectArtist = (artist) => {
    setArtistDataSelected((prevSelected) => {
      if (
        prevSelected.find(
          (a) => a.spotify_artist_name === artist.spotify_artist_name
        )
      ) {
        return prevSelected; // Prevent adding the same artist multiple times
      }
      return [...prevSelected, artist];
    });
    setShowFullWrap(false);
    clearSearchInput();
  };

  const removeSelectedArtist = (artistName) => {
    setArtistDataSelected((prevSelected) =>
      prevSelected.filter((artist) => artist.spotify_artist_name !== artistName)
    );
  };

  const toggleFullWrap = () => {
    setShowFullWrap(!showFullWrap); // Toggle visibility of fullWrap
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value.trim() === "") {
      setArtistData([]); // Clear artist data if input is empty
      setShowFullWrap(false); // Optionally, hide the dropdown or search results display
    } else {
      // Call debounced search only if input is not empty
      debouncedSearch(value.trim());
    }
  };

  const handleContinue = async () => {

    const abortController = new AbortController();
    const signal = abortController.signal;

    const payload = {
      uid: userInfo.id,
      displayName: userInfo.name,
      email: userInfo.email,
      photoURL: userInfo.picture,
      artist_1: artistDataSelected[0].spotify_artist_id,
      artist_2: artistDataSelected[1].spotify_artist_id,
      artist_3: artistDataSelected[2].spotify_artist_id,
      artist_4: artistDataSelected[3].spotify_artist_id,
      artist_5: artistDataSelected[4].spotify_artist_id,
      type_of_query: 'Post'
    };

    try {

        const queryStringData = new URLSearchParams(payload).toString();
        const url = `https://gkwimrhil6.execute-api.eu-north-1.amazonaws.com/default/5_User_data_EU_NORTH_1?${queryStringData}`;
        console.log(url)
        const response = await fetch(url, { signal });
      

      if (response.ok) {
        window.location.href = "/inicio";
      } else {
      }
    } catch (error) {
    }
  };

  return (
    <>
      <Modal isOpen={isOpenModal}>
        <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
          <div className="login__WelcomeEv">Bienvenido a Eventhio</div>
          <div className="login__Welcome">
            Para ayudarnos a encontrar los mejores conciertos para ti, por favor
            selecciona los 5 artistas que más te gusten
          </div>
          <div className="login__searchBar">
            <div className="mgWrap">
              <div className="mg"></div>
              <input
                type="text"
                className="searchBarWrite"
                placeholder="Artistas, conciertos, lugares..."
                value={searchInput}
                onChange={handleInputChange}
              ></input>
            </div>
            <div className="clearInput" onClick={clearSearchInput}></div>
            <div
              className={`login__dropdown ${
                showFullWrap ? "login__dropdownUp" : ""
              }`}
              onClick={toggleFullWrap}
            ></div>
          </div>
          <div style={{ height: "10px" }}></div>
          <div
            className="login__FullWrap"
            style={{ display: showFullWrap ? "block" : "none" }}
          >
            <div className="login__artistTagWrap">
              {artistData.map((artist) => (
                <div
                  key={artist.spotify_artist_name}
                  className="login__artistTag"
                  onClick={() => selectArtist(artist)}
                >
                  <div
                    className="login__tagArtistPhoto"
                    style={{
                      backgroundImage: `url(${artist.spotify_artist_photo})`,
                    }}
                  ></div>
                  <div className="login__tagArtistName">
                    {artist.spotify_artist_name}
                  </div>
                </div>
              ))}
              <div style={{ height: "100px" }}></div>
            </div>
          </div>
          <div className="login__selectedTitle">
            {" "}
            Tus artistas seleccionados
          </div>

          <div className="login__SelArtists">
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                className="login__SelArtistsOne"
                style={{
                  backgroundImage:
                    artistDataSelected.length > index
                      ? `url(${artistDataSelected[index].spotify_artist_photo})`
                      : "none",
                }}
              >
                {artistDataSelected.length > index && (
                  <div className="login__removeArtistButtonWrap">
                    <div
                      className="login__removeArtistButton"
                      onClick={() =>
                        removeSelectedArtist(
                          artistDataSelected[index].spotify_artist_name
                        )
                      }
                    ></div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div
            className={
              artistDataSelected.length < 5
                ? "login__continuarBad"
                : "login__continuarGood"
            }
            onClick={handleContinue}
          >
            Continuar
          </div>
        </div>
      </Modal>
    </>
  );
}


function UserDetailsNew() {
    
  
    return (
      <div>
        <ModalSelectSingers isOpenModal={true} />
      </div>
    );
  }
  
  export default UserDetailsNew;
export { UserDetailsNew };
