import React, { useState, useEffect, useRef } from "react";
import {
  formatDate_2,
  convertTimestampToDateStr,
} from "../../functions/0_side_functions.js";
import { useParams } from "react-router-dom";
import {
  handleArtistClick,
  handleCloseButton,
  handleConcertClick,
  handleLoginPage,
} from "../../functions/1_handle_clicks.js";
import SEO from "../../functions/3_SEO.js";
import { useAuth } from "../../functions/2_auth_context.js";
import { v4 as uuidv4 } from 'uuid';

function ArtistDetails() {
  const [artistSimilar, setArtistSimilar] = useState({});
  const [artistAll, setArtistAll] = useState({});
  const [artistPhoto, setArtistPhoto] = useState({});
  const [artistLiked, setArtistLiked] = useState({});
  const [loading, setLoading] = useState(true);
  const { userInfo } = useAuth();
  const { spotify_artist_id } = useParams();
  const { artist_name } = useParams();
  const type_of_query = "Artists";
  const user_id_code = userInfo ? userInfo.eventhio_id : "null";
  const queryString = new URLSearchParams({
    artist_name,
    spotify_artist_id,
    type_of_query,
    user_id: user_id_code,
  }).toString();
  const getUniqueCities = (artistAll) => {
    const cities = artistAll.map((artist) => artist.city);
    return [...new Set(cities)];
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const queryStringData = new URLSearchParams(queryString).toString();
        const url = `https://68gnhmjcw2.execute-api.eu-north-1.amazonaws.com/default/1_Complete_modal_EU_NORTH_1?${queryStringData}`;
        const response = await fetch(url, { signal });
        const data = await response.json();

        const data_artist_all = JSON.parse(data.artist_all_concerts);
        const data_artist_similar = JSON.parse(data.artist_similar_artist);
        const data_artist_photo = JSON.parse(data.artist_photo);
        const data_artist_liked = JSON.parse(data.user_liked_artists);

        setArtistAll(data_artist_all);
        setArtistSimilar(data_artist_similar);
        setArtistPhoto(data_artist_photo);
        setArtistLiked(data_artist_liked);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();

    return () => {
      abortController.abort(); // Cleanup function to cancel the fetch request
    };
  }, [queryString]);

  useEffect(() => {
    const saveInfo = async () => {
      let cookieId = localStorage.getItem('cookieId');
      if (!cookieId) {
        cookieId = uuidv4();
        localStorage.setItem('cookieId', cookieId);
      }

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let userId = userInfo && userInfo.id ? userInfo.id : null;

      const payload = {
        type_of_query: "PostInfo",
        url: window.location.href,
        user_id: user_id_code,
        ev_cookie_id: cookieId,
      };
      try {
        const queryStringData = new URLSearchParams(payload).toString();
        const url = `https://6l04gpt4e4.execute-api.eu-north-1.amazonaws.com/default/8_Database_post_delete_EU_NORTH_1?${queryStringData}`;
        console.log(url)
        await fetch(url);
      } catch (error) {
      }
    };

    saveInfo();
  }, []);




  const isArtistLiked = (artistId) => {
    return artistLiked.some((artist) => artist.spotify_artist_id === artistId);
  };

  const toggleLikeArtist = async (artistId) => {
    if (!userInfo) {
      handleLoginPage();
      return;
    }
    const typeOfQuery = isArtistLiked(artistId) ? "RemoveArtist" : "PostArtist";
    const payload = {
      type_of_query: typeOfQuery,
      user_id: user_id_code,
      artist_id: artistId,
    };

    try {
      const queryStringData = new URLSearchParams(payload).toString();
      const url = `https://6l04gpt4e4.execute-api.eu-north-1.amazonaws.com/default/8_Database_post_delete_EU_NORTH_1?${queryStringData}`;
      console.log(url)
      const response = await fetch(url);
      if (response.ok) {
        setArtistLiked((prevArtistLiked) => {
          if (isArtistLiked(artistId)) {
            return prevArtistLiked.filter(
              (artist) => artist.spotify_artist_id !== artistId
            );
          } else {
            return [
              ...prevArtistLiked,
              {
                eventhio_user_id: userInfo.id,
                spotify_artist_id: artistId,
              },
            ];
          }
        });
      }
    } catch (error) {
    }
  };

  const artistName = artist_name;
  const scrollRefs = useRef([]);
  if (!scrollRefs.current.length) {
    // Adjust the number based on your actual number of scrollable sections
    scrollRefs.current = Array(1)
      .fill()
      .map(() => React.createRef());
  }

  const scrollLeft = (index) => {
    const element = scrollRefs.current[index].current;
    if (element) {
      const scrollAmount = element.offsetWidth * 1;
      element.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const scrollRight = (index) => {
    const element = scrollRefs.current[index].current;
    if (element) {
      const scrollAmount = element.offsetWidth * 1;
      element.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  if (loading) {
    return <div className="loadingScreen"></div>;
  } else {
    const isConcertsAvailable = artistAll.length > 0;
    const divStyle = {
      backgroundImage: `url(${artistPhoto[0].spotify_artist_photo})`,
    };

    const uniqueCities = isConcertsAvailable ? getUniqueCities(artistAll) : [];

    /* SEO ELEMENTS */
    const seoTitle = `${artistPhoto[0].spotify_artist_name} | Eventhio`;
    const seoDescription = `Encuentra todos los conciertos de ${artistPhoto[0].spotify_artist_name}. ¡Compra ya tus entradas!`;
    const seoUrl = `https://www.eventhio.com/artistas/${artistPhoto[0].spotify_artist_name.replace(
      / /g,
      "-"
    )}/${artistPhoto[0].spotify_artist_id}`;
    const seoPhoto = `url(${artistPhoto[0].spotify_artist_photo})`;
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoDescription}
          url={seoUrl}
          image={seoPhoto}
        />

        <div className="pageWrapper">
          <div className="concert__TopSpace"></div>
          <div className="pageWrapper_SecondLevel">
            <div className="concert_BackBlack" style={divStyle}></div>
            <div className="artist__artistPhoto" style={divStyle}>
              <div className="closeButtonWrap" onClick={() => handleCloseButton()}>
                <div className="closeButton"></div>
              </div>
              <div
                className="loveButtonWrap"
                onClick={() =>
                  toggleLikeArtist(artistPhoto[0].spotify_artist_id)
                }
              >
                {isArtistLiked(artistPhoto[0].spotify_artist_id) ? (
                  <div className="loveButtonFilled"></div>
                ) : (
                  <div className="loveButton"></div>
                )}
              </div>
              <div
                className="sendButtonWrap"
              >
                <div className="sendButton"></div>
              </div>
            </div>
            <div className="concertInfo__Wrapper">
              <div className="artist__artist">Artista</div>
              <h1 className="artist_Name">
                {artistPhoto[0].spotify_artist_name}
              </h1>
            </div>
            <div className="artist__Wrap">
              {isConcertsAvailable ? (
                <div className="aritst__allCitiesWrap">
                  {uniqueCities.map((city, index) => (
                    <div key={city} className="concertLocationWrap">
                      <div className="artist__cityName">{city}</div>
                      {artistAll
                        .filter((artist) => artist.city === city)
                        .map((artist, artistIndex) => (
                          <div
                            key={artist.concert_name + artist.local_date_start}
                          >
                            <div className="artist__">
                              <div className="concert__Other" key={artistIndex}>
                                <div className="concert__dateInfoWrap">
                                  <div className="date__Wrap">
                                    <div className="date__">
                                      {formatDate_2(
                                        convertTimestampToDateStr(artist.local_date_start)
                                      ).monthAbbreviation}
                                    </div>
                                    <div className="date_Number">
                                      {formatDate_2(
                                        convertTimestampToDateStr(artist.local_date_start)
                                      ).day}
                                    </div>
                                    <div className="date__">
                                      {formatDate_2(
                                        convertTimestampToDateStr(artist.local_date_start)
                                      ).year}
                                    </div>
                                  </div>

                                  <div className="concert__InfoNameLocWrap">
                                    <div className="concert__InfoName">
                                      {artist.concert_name}
                                    </div>
                                    <div>
                                      {artist.city} - {artist.concert_place}
                                    </div>

                                  </div>
                                </div>
                                <div className="concert__Ver" onClick={() => handleConcertClick(artist)}>VER</div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="concert__NoconcertWrap">
                  <div className="concert__NoconcertText">

                    No hay conciertos programados para este artista. Sigue a{" "}
                    {artistPhoto[0].spotify_artist_name} para enterarte de sus
                    próximas actuactiones.
                  </div>
                </div>
              )}
              {artistPhoto[0].spotify_artist_description &&
                artistPhoto[0].spotify_artist_description !== "" ? (
                <div className="concert__Info">
                  <h1 className="concert__Info__ArtistTitle">
                    Más acerca de{" "}
                    {artistPhoto[0].spotify_artist_name.replace("-", " ")}
                  </h1>
                  <div className="concert__Info_Text">
                    {artistPhoto[0].spotify_artist_description}
                  </div>
                  <div className="artist__InfoGap"></div>
                </div>
              ) : (
                <div style={{ height: "0px" }}></div>
              )}
            </div>
            <div className="concert__Similar_Wrap">

              {artistSimilar.length > 0 && (
                <>
                  <h2 className="concert__SimilarTitle">Artistas similares</h2>
                  <div className="concert__rowWrap">

                    <div
                      className="concert__SimilarRowArtist"
                      key="event_artists"
                      ref={scrollRefs.current[0]}
                    >
                      {artistSimilar.map((artist, index) => {
                        const divStyle = {
                          backgroundImage: `url(${artist.spotify_artist_photo})`,
                          backgroundSize: "cover",
                        };
                        return (
                          <div key={artist.spotify_artist_id + index}>
                            <div
                              className="concert__artistSimilarConcert"
                              style={divStyle}
                              onClick={() => handleArtistClick(artist)}
                            ></div>
                            <div className="concert__Subbox">
                              <div className="concert__SimilarArtistName">
                                {artist.spotify_artist_name}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>


      </>
    );
  }
}

export default ArtistDetails;
