import {
  formatDate,
  formatDateformTimestamp,
} from "../../functions/0_side_functions.js";
import React, { useState, useEffect, useRef } from "react";
import {
  handleConcertClick,
  handleArtistClick,
} from "../../functions/1_handle_clicks.js";
import { useAuth } from "../../functions/2_auth_context.js";
import Modal from "react-modal";
import cities from "../../cities/ES.json";
import SEO from "../../functions/3_SEO.js";
import { v4 as uuidv4 } from 'uuid';

function ModalLocation({ isOpenModal, onClose }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredCities([]);
      return;
    }
    const normalizeString = (str) =>
      str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    const normalizedSearchTerm = normalizeString(searchTerm);
    const results = Object.keys(cities)
      .filter((city) => normalizeString(city).includes(normalizedSearchTerm))
      .sort((a, b) => a.localeCompare(b, "es", { sensitivity: "base" }));
    setFilteredCities(results);
  }, [searchTerm]);

  const updateCityMemory = (cityName) => {
    const defaultLocation = {
      city: cityName,
      Lat: cities[cityName]["Lat"],
      Long: cities[cityName]["Long"],
    };
    localStorage.setItem("location", JSON.stringify(defaultLocation));
    window.location.href = `${window.location.origin}`;
  };

  return (
    <>
      <Modal isOpen={isOpenModal}>
        <div style={{ backgroundColor: "white", minHeight: "100vh", zIndex: "100" }}>
          <div className="city__WrapTotal">
            <div className="city__topWrap">
              <div className="city__WelcomeEv">Selecciona tu ciudad</div>
              <div className="city__Close" onClick={onClose}></div>
            </div>
            <div className="citySelection__Wrap">
              <div className="search__BarWrap">
                <div className="search__Glass"></div>
                <input
                  type="text"
                  className="search__Input"
                  placeholder="Encuentra lo que quieras..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                ></input>
                <div className="search__clearInput" onClick={() => setSearchTerm("")}></div>
              </div>

            </div>


            <div className="city__resultWrap">
              {filteredCities.map((city) => (
                <div
                  key={city}
                  className="city__resultItem"
                  onClick={() => {
                    updateCityMemory(city);
                  }}
                >
                  {city}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function ModalLoading({ isOpenModal }) {
  return (
    <>
      <Modal
        isOpen={isOpenModal}
        className="loading__Top"
      >
        <div>
          <div className="loading__Wrap">
            <div className="login__LogoGap"></div>
            <div className="loading__Modal">
              <div className="login__Logo"></div>
              <div className="login__Welcometext">
                Cargando información musical
              </div>
              <div className="loading__Spinner"></div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function CreateEventName({ index, rowData }) {
  const rowDataInd = rowData[index];
  const divStyle = {
    backgroundImage: `url(${rowDataInd.concert_image})`,
  };
  const divStyle2 = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(${rowDataInd.concert_image})`,
    backgroundSize: "cover",
    borderRadius: "4px",
  };
  return (
    <div key={index + "_" + rowDataInd.tm_concert_id}>
      <div>
        <div style={divStyle2}>
          <div
            className="concert__SimilarEvent"
            style={divStyle}
            onClick={() => handleConcertClick(rowDataInd)}
          ></div>
        </div>
        <div className="concert__Subbox">
          <div className="concert__SimilarEventName">{rowDataInd.spotify_artist_name}</div>
          <div className="concert__SimilarEventLocDate">
            {rowDataInd.city}
          </div>
          <div className="concert__SimilarEventDate">
            {formatDate(formatDateformTimestamp(rowDataInd.local_date_start))}
          </div>
        </div>
      </div>
    </div>
  );
}

function CreateArtistName({ index, rowData }) {
  const rowDataInd = rowData[index];
  const divStyle = {
    backgroundImage: `linear-gradient(rgba(20,20,20,0), rgba(20,20,20,0)), url(${rowDataInd.spotify_artist_photo})`,
    backgroundSize: "cover",
  };

  return (
    <div key={index + "_" + rowDataInd.concert_id}>
      <div
        className="concert__artistSimilar"
        style={divStyle}
        onClick={() => handleArtistClick(rowDataInd)}
      ></div>
      <div className="concert__Subbox">
        <div className="concert__SimilarArtistName">{rowDataInd.spotify_artist_name}</div>
      </div>
    </div>
  );
}

function HomePage() {
  const [concertData, setConcertData] = useState([]);
  const [artistData, setArtistData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCity, setLoadingCity] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savedLocation, setSavedLocation] = useState(null);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const { userInfo, isLoadingInfo, handleLogin_Google, handleLogin_Spotify } = useAuth();
  const isLogged = userInfo ? true : false;
  const scrollRefs = useRef([]);
  if (!scrollRefs.current.length) {
    scrollRefs.current = Array(9)
      .fill()
      .map(() => React.createRef()); // Assuming there are 9 sections
  }

  const scrollLeft = (index) => {
    if (scrollRefs.current[index].current) {
      const element = scrollRefs.current[index].current;
      const scrollAmount = element.offsetWidth * 0.75; // 75% of the element's width
      element.scrollBy({
        left: -scrollAmount, // Negative for left scroll
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (index) => {
    if (scrollRefs.current[index].current) {
      const element = scrollRefs.current[index].current;
      const scrollAmount = element.offsetWidth * 0.75; // 75% of the element's width
      element.scrollBy({
        left: scrollAmount, // Positive for right scroll
        behavior: "smooth",
      });
    }
  };

  const fetchEventData = async (city) => {
    let token;
    if (userInfo && userInfo.context === "Google") {
      token = userInfo.eventhio_id;
    } else if (userInfo && userInfo.context === "Spotify") {
      token = userInfo.authToken;
    } else {
      token = "null";
    }
    const auth_method = userInfo ? userInfo.context : "null";
    const queryParameters = { token, auth_method, city_url: city };

    const queryString = new URLSearchParams(queryParameters).toString();
    const url = `https://8vy15dade7.execute-api.eu-north-1.amazonaws.com/default/0_Create_rows_initial_EU_NORTH_1?${queryString}`;
    console.log(url)
    try {
      setLoading(true);
      const response = await fetch(url);
      const data = await response.json();
      const data_concert = JSON.parse(data.concerts);
      const data_artist = JSON.parse(data.artists);
      setConcertData(data_concert);
      setArtistData(data_artist);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const location = localStorage.getItem("location");
    if (!location) {
      const defaultLocation = {
        city: "Madrid",
        Lat: 40.41669,
        Long: -3.700346,
      };
      localStorage.setItem("location", JSON.stringify(defaultLocation));
      setSavedLocation(defaultLocation);
    } else {
      setSavedLocation(JSON.parse(location));
    }
    setLoadingCity(false);
  }, []);

  useEffect(() => {
    if (savedLocation && !initialFetchDone) {
      fetchEventData(savedLocation.city).then(() => {
        setInitialFetchDone(true);
      });
    }
  }, [savedLocation, initialFetchDone]);

  useEffect(() => {
    if (savedLocation && initialFetchDone && !isLoadingInfo) {
      fetchEventData(savedLocation.city);
    }
  }, [isLoadingInfo]);


  useEffect(() => {
    const saveInfo = async () => {
      let cookieId = localStorage.getItem('cookieId');
      if (!cookieId) {
        cookieId = uuidv4();
        localStorage.setItem('cookieId', cookieId);
      }

      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let userId = userInfo && userInfo.eventhio_id ? userInfo.eventhio_id : null;

      const payload = {
        type_of_query: "PostInfo",
        url: window.location.href,
        user_id: userId,
        ev_cookie_id: cookieId,
      };
      try {
        const queryStringData = new URLSearchParams(payload).toString();
        const url = `https://6l04gpt4e4.execute-api.eu-north-1.amazonaws.com/default/8_Database_post_delete_EU_NORTH_1?${queryStringData}`;
        await fetch(url);
      } catch (error) {
      }
    };

    saveInfo();
  }, []);


  const updateCityMemory = (cityName) => {
    const newLocation = {
      city: cityName,
      Lat: cities[cityName]["Lat"],
      Long: cities[cityName]["Long"],
    };

    if (JSON.stringify(newLocation) !== JSON.stringify(savedLocation)) {
      localStorage.setItem("location", JSON.stringify(newLocation));
      setSavedLocation(newLocation);
      setIsModalOpen(false);
    }
  };

  if (loading || loadingCity) {
    return <div className="loadingScreen"></div>;
  } else {
    // Combine the data and titles in the desired order
    const combinedDataAndTitles = [
      { title: "Conciertos top", data: concertData.slice(0, 10) },
      { title: "Los más buscados", data: concertData.slice(10, 20) },
      { title: "Nuestros favoritos", data: concertData.slice(20, 30) },
      { title: "No te lo puedes perder", data: concertData.slice(30, 40) },
      {
        title: "Artistas top",
        data: artistData.slice(0, 12),
        isArtist: true,
      },
      { title: "Dentro de nada", data: concertData.slice(40, 50) },
      { title: "Cerca de tu zona", data: concertData.slice(50, 60) },
      {
        title: "Más artistazos",
        data: artistData.slice(12, 24),
        isArtist: true,
      },
      { title: "Alternativos", data: concertData.slice(60, 70) },
    ];

    const seoTitle = `Eventhio | Encuentra los mejores conciertos en tu ciudad`;
    const seoDescription = `Encuentra todos los conciertos de los mejores artistas en tu ciudad y obtén recomendaciones personalizadas en función de tus gustos musicales. ¡Compra ya tus entradas!`;
    const seoUrl = `https://www.eventhio.com`;
    const seoPhoto = `url('../img/eventhio_small.png')`;

    return (
      <>
        <SEO
          title={seoTitle}
          description={seoDescription}
          url={seoUrl}
          image={seoPhoto}
        />
        <ModalLocation
          isOpenModal={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          updateCityMemory={updateCityMemory}
        />
        <ModalLoading
          isOpenModal={isLoadingInfo}
        />






        {/* <div className="homepage__movingStrip">
          <div class="movingText">
            <div className="homepage__movingStripWrap">
              <div className="homepage__movingStripText">INICIA SESIÓN</div>
              <div className="login__LogoWrap">
                <div className="login__GoogleLogo"></div>
              </div>
              <div className="login__LogoWrap">
                <div className="login__SpotifyLogo"></div>
              </div>
            </div>

            <div className="homepage__movingStripWrap">
              <div className="homepage__movingStripText">INICIA SESIÓN</div>
              <div className="login__LogoWrap">
                <div className="login__GoogleLogo"></div>
              </div>
              <div className="login__LogoWrap">
                <div className="login__SpotifyLogo"></div>
              </div>
            </div>

            <div className="homepage__movingStripWrap">
              <div className="homepage__movingStripText">INICIA SESIÓN</div>
              <div className="login__LogoWrap">
                <div className="login__GoogleLogo"></div>
              </div>
              <div className="login__LogoWrap">
                <div className="login__SpotifyLogo"></div>
              </div>
            </div>

            <div className="homepage__movingStripWrap">
              <div className="homepage__movingStripText">INICIA SESIÓN</div>
              <div className="login__LogoWrap">
                <div className="login__GoogleLogo"></div>
              </div>
              <div className="login__LogoWrap">
                <div className="login__SpotifyLogo"></div>
              </div>
            </div>

            <div className="homepage__movingStripWrap">
              <div className="homepage__movingStripText">INICIA SESIÓN</div>
              <div className="login__LogoWrap">
                <div className="login__GoogleLogo"></div>
              </div>
              <div className="login__LogoWrap">
                <div className="login__SpotifyLogo"></div>
              </div>
            </div>

          </div>

        </div> */}
        <div className="homepage__FrontWrap">

          <div className="homepage__frontPageWrap">
            <div className="homepage__frontPageText">
              <span>Encuentra tus conciertos favoritos cerca de </span>
              <span
                className="ciudadColor"
                style={{ color: "#0dcdaa" }}
                onClick={() => setIsModalOpen(true)}
              >
                {savedLocation.city}
              </span>
            </div>
            <>
              {isLogged ?

                (
                  <div className="homepage__frontPageText3Wrap">
                    <div className="homepage__frontPageText2">

                      <span>
                        Hola </span>
                      <span>
                        {userInfo.name}
                      </span>
                      <span>. Sigue conciertos y añade artistas a tus favoritos para que nuestro algoritmo </span>
                      <span className="algoritmo">te recomiende lo mejor.</span>
                    </div>
                  </div>


                )

                : (
                  <div className="homepage__frontPageText2Wrap">
                    <div className="homepage__frontPageText2">
                      Loggeate para acceder a recomendaciones personalizadas en función a tus gustos musicales.
                    </div>
                    <div className="login__Wrap">
                      <div className="login__Google_Front" onClick={handleLogin_Google}>
                        <div className="login__GoogleLogo"></div>
                        <div className="login__text">Continuar con Google</div>
                      </div>
                      <div className="login__Spotify_Front" onClick={handleLogin_Spotify}>
                        <div className="login__SpotifyLogo"></div>
                        <div className="login__text">Continuar con Spotify</div>
                      </div>
                    </div>
                  </div>
                )}</>


          </div>
          <div className="homepage__Concert_photo">

          </div>


          {combinedDataAndTitles.map((section, index) => (
            <div key={section.title + "_" + index}>
              <h2 className="concert__SimilarTitle">{section.title}</h2>
              <div className="homepage__rowEventArtistWrap">
                <div
                  className="scrollButton h_left"
                  onClick={() => scrollLeft(index)}
                ></div>
                <div
                  className="scrollButton h_right"
                  onClick={() => scrollRight(index)}
                ></div>
                <div
                  className={
                    section.isArtist
                      ? "homepage__rowArtist"
                      : "homepage__rowEvent"
                  }
                  ref={scrollRefs.current[index]}
                >
                  {section.data.map((item, itemIndex) =>
                    section.isArtist ? (
                      <CreateArtistName
                        key={item.concert_id + "_artist_" + itemIndex} // Unique key here
                        index={itemIndex}
                        rowData={section.data}
                      />
                    ) : (
                      <CreateEventName
                        key={item.concert_id + "_event_" + itemIndex} // Unique key here
                        index={itemIndex}
                        rowData={section.data}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          ))}

          <div style={{ height: "35px" }}></div>
        </div>
      </>
    );
  }
}

export default HomePage;
