import { format } from "date-fns";
import { es } from "date-fns/locale";
export {
  parseDate,
  formatDate,
  formatDate_2,
  formatDateformTimestamp,
  formatDateformTimestamp_full,
  formatDateLong,
  formatDateLongDay,
  formatDateISO,
  convertTimestampToDateStr
};

// 1) Date functions
function parseDate(dateString) {
  // // Assuming dateString is in format "dd/mm/yyyy"
  // const parts = dateString.split('/');
  // const day = parseInt(parts[0]);
  // const month = parseInt(parts[1]) - 1; // Months are zero-indexed in JavaScript Date
  // const year = parseInt(parts[2]);
  // return new Date(year, month, day);
  // Assuming dateString is in format "dd/mm/yyyy"
  const parts = dateString.split("/");
  const day = parseInt(parts[0]);
  const month = parseInt(parts[1]) - 1; // Months are zero-indexed in JavaScript Date
  const year = parseInt(parts[2]);
  return new Date(year, month, day);
}

function formatDate(dateString) {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "es-ES",
    options
  );
  // Capitalize the first letter of the month abbreviation
  return formattedDate.replace(/\b\w/g, (char) => char.toUpperCase());
}

function formatDateLong(dateString) {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "es-ES",
    options
  );
  // Capitalize the first letter of the month abbreviation
  return formattedDate;
}

function formatDateLongDay(dateString) {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "es-ES",
    options
  );

  // Capitalize the first letter of the day and month
  const formattedDateCapitalized = formattedDate.replace(/^(.)/, (match) =>
    match.toUpperCase()
  );

  return formattedDateCapitalized;
}

function formatDateformTimestamp(timestamp_value) {
  const date = new Date(timestamp_value);
  // Get the year, month, and day from the date object
  const year = parseInt(date.getFullYear());
  const month = parseInt(date.getMonth()); // Month is 0-indexed; add 1 to match the calendar month
  const day = parseInt(date.getDate());
  return new Date(year, month, day);
  // Format the date string in 'YYYY-MM-DD' format
}

function formatDateformTimestamp_full(timestamp_value) {
  const date = new Date(timestamp_value);
  const formattedDate =
    format(date, "eeee, d 'de' MMMM 'de' yyyy", { locale: es });
  return formattedDate;
}

function formatDate_2(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthAbbreviation = format(date, "MMM", { locale: es }).toUpperCase();
  const year = date.getFullYear();
  return { day, monthAbbreviation, year };
}

function formatDateISO(timestamp) {
  const date = new Date(timestamp);
  const isoFormat = date.toISOString().substring(0, 10);
  return isoFormat;
}

function convertTimestampToDateStr(timestamp) {
  const date = new Date(timestamp);
  // Returns a date string in a format like "2023-05-01T14:56:23.000Z"
  return date.toISOString();
}
